import { useEffect, memo, Fragment, useContext } from "react";
import { useLocation, Outlet } from "react-router-dom";

//react-shepherd
import {  ShepherdTourContext } from "react-shepherd";

//react-bootstrap
import { Link } from "react-router-dom";

// header
import Header from "../../components/partials/dashboard/HeaderStyle/header";

//subheader
import SubHeader from "../../components/partials/dashboard/HeaderStyle/sub-header";

//sidebar
import Sidebar from "../../components/partials/dashboard/SidebarStyle/sidebar";

//footer
import Footer from "../../components/partials/dashboard/FooterStyle/footer";

//default
// import {DefaultRouter} from '../../router/default-router'

//seetingoffCanvas
import SettingOffCanvas from "../../components/setting/SettingOffCanvas";

import Loader from "../../components/Loader";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";


const DefaultTemp = memo((props) => {
  const appName = useSelector(SettingSelector.app_name);
  useEffect(() => {});

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
});

DefaultTemp.displayName = "Default";
export default DefaultTemp;
