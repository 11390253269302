import { useEffect } from 'react';
import { Toast } from 'react-bootstrap';

const ToastNotification = ({type, show_toast, set_show_toast, message, time = 7000}) => {

    useEffect(() => {

        const interval = setInterval(() => {
            set_show_toast(false);
        }, time);
        
        return () => {
            clearInterval(interval);
        }

    }, [set_show_toast, time]);

    return (
        
        <Toast id='toast-container-main' role="alert" onClose={() => set_show_toast(false)} show={show_toast} aria-live="assertive" aria-atomic="true" className={`bg-${type}`}>
            <div className='d-flex'>
                <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                <Toast.Body>{message}</Toast.Body>
            </div>
        </Toast> 

    );

}

export default ToastNotification;