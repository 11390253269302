import React, { useState, useEffect, useRef } from 'react'
import {Row,Col,Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Card from '../../../components/Card'
import { API_CALL_CENTRE_USERS_LIST, API_USERS_PROFILE } from '../../../service/url';
import { getApiCall, deleteApiCallWithHeader } from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { getUser } from '../../../utilities/setting';
import RenderUserAccessRolesList from '../../../components/partials/components/user-access-roles-list';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import { ROUTES } from '../../../utilities/constant';
import { useAuth, errorCheck } from '../auth/auth-utils';
import { useNavigate } from "react-router-dom";

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';

const CallCentreUsersList = () =>{
   const { checkAuth } = useAuth();
   const navigate = useNavigate();
   const [userlist, setUserlist] = useState([]);
   const [activeUserAccessRole, setActiveUserAccessRole] = useState(undefined)
   const [userAccessRoles, setUserAccessRoles] = useState([]);
   const [filters, setFilters] = useState({
      username: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      company_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
   });
   const statuses = useRef(['Active', 'Inactive']);
   const [callCentreName, setCallCentreName] = useState('');
   const [callCentreId, setCallCentreId] = useState(undefined);
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [deleteUserId, setDeleteUserId] = useState(undefined);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);

   function getProfileDeleteApiUrl(id){
      return API_USERS_PROFILE.replace(':id', id);      
   }

   function getCallCentreUsersListApiUrl(id){
      return API_CALL_CENTRE_USERS_LIST.replace(':id', id);
   }
   

   useEffect( () => {

      if(toastProps){
         setShowToast(true);
      }

   }, [toastProps]);



   useEffect( () => {

      getUser().then(resp => {
         if(resp){
            setCallCentreName(resp.username);
            setCallCentreId(resp.id);
         }
      })
      .catch(onError);

   }, []);


   useEffect( () => {

      if(callCentreId){

         const callCentreUsersApiUrl = getCallCentreUsersListApiUrl(callCentreId);
         getApiCall(callCentreUsersApiUrl, {})
         .then(onUserListSuccess)
         .catch(onError);

      }

   }, [callCentreId]);

   function onUserListSuccess(resp){
      const updatedList = resp.map( (val, idx) => {
         val['key'] = idx+1;
         val['actions'] = RenderActionComponent(callCentreId, val._id);
         val['createdAt'] = new Date(val['createdAt']).toGMTString();
         return val;
      } );
      setUserlist(updatedList);
   }

   function handleDeleteUser(id){
      checkAuth(setToastProps, setShowToast);
      setEnableDeleteModal(true);
      setDeleteUserId(id);
   }

   useEffect( () => {

      if(confirmDelete){
         const deleteProfileApiUrl = getProfileDeleteApiUrl(deleteUserId);
         deleteApiCallWithHeader(deleteProfileApiUrl, {})
         .then(onUserDeleteSuccess)
         .catch(onUserDeleteFail);
      }

   }, [confirmDelete]);


   function onUserDeleteSuccess(resp){
      setToastProps({type: 'success', message: 'User deleted successfully'});
      const updatedList = userlist.filter(val => val._id != deleteUserId);
      setUserlist(updatedList);
      setEnableDeleteModal(false);
      setDeleteUserId(undefined);
   }

   function onUserDeleteFail(err){
      console.log(err);
      setToastProps({type: 'danger', message: `Unable to delete user: ${err}`});
   }

   function RenderActionComponent(callCentreId, id){

      const editUserLink = ROUTES.CALL_CENTRES.USERS.EDIT.replace(':id', callCentreId).replace(':uid', id);

      return (
         <div className="flex align-items-center list-user-action">
            <Link className="btn btn-sm btn-icon btn-warning" data-toggle="tooltip" data-placement="top" title="Edit" data-original-title="Edit" to={`/${editUserLink}`}>
               <span className="btn-inner">
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
               </span>
            </Link>{' '}
            <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="Delete" data-original-title="Delete" to="#" onClick={() => handleDeleteUser(id)}>
               <span className="btn-inner">
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                     <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
               </span>
            </Link>{' '}
         </div>
      );

   }

   const dropdownItemTemplate = (option) => {
      return <span>{option}</span>;
   }

   const statusFilterTemplate = (options) => {
      return <Dropdown value={options.value} options={statuses.current} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
   }

   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
       errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
      console.log(err)
    }
   }

   const handleCreateButtonClick = () => {
      navigate(`/${ROUTES.USERS.CREATE}`);
   } 

  return(
     <>
       <div>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-block justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">{callCentreName} Users List</h4>
                     </div>
                     <button className="btn btn-primary ml-auto" onClick={handleCreateButtonClick}>Create User</button>
                  </Card.Header>
                  <Card.Body className="px-0">
                     
                     <div className="table-responsive border-bottom my-3">
                        <DataTable value={userlist} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={30}>
                           <Column field="key" header="#"></Column>
                           <Column field="username" filterField="username" header="Username" filter filterPlaceholder="Search by username" ></Column>
                           <Column field="name" filterField="name" header="Name" filter filterPlaceholder="Search by name" ></Column>
                           <Column field="email" filterField="email" header="Email" filter filterPlaceholder="Search by email" ></Column>
                           <Column field="status" header="Status" filterElement={statusFilterTemplate} filter></Column>
                           <Column field="createdAt" header="Joining Date"></Column>
                           <Column field="actions" header="Actions"></Column>
                        </DataTable>
                     </div>

                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </div>

      <RenderDeleteModal show={enableDeleteModal} setShow={setEnableDeleteModal} setConfirmDelete={setConfirmDelete} />

      <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

     </>
  )

}

export default CallCentreUsersList;