import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import { API_DATA_CENTER_LIST } from "../../../service/url";
import {
  SERVER_MANAGEMENT_DOMESTIC_TYPE,
  SERVER_MANAGEMENT_INTERNATIONAL,
  SERVER_TYPES,
} from "../../../utilities/constant";
import { getApiCall, postApiCall } from "../../../service/httpCall";
import { postApiMediaCall } from "../../../service/postApiCall";

export default function RenderServerManagementForm(props) {
  const [dataCenterList, setDataCenterList] = useState([]);

  useEffect(() => {
    getApiCall(API_DATA_CENTER_LIST, {})
      .then(onDataCenterListSuccess)
      .catch(onError);
  }, []);

  function onDataCenterListSuccess(resp) {
    setDataCenterList(resp);
  }

  function onError(err) {
    console.log(err);
  }
  return (
    <>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Select Data Center:</Form.Label>
          )}
          <Form.Select
            id="dataCenter_Id"
            required
            value={props.dataCenterId}
            className="p-2"
            onChange={(e) => props.setDataCenterId(e.target.value)}
          >
            <option value={""}>Select Data Center</option>
            {dataCenterList.map(function (val, idx) {
              return (
                <option key={idx} value={val._id}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Select Server Type:</Form.Label>
          )}
          <Form.Select
            id="dataServer_type"
            value={props.serverType}
            className="p-2"
            onChange={(e) => props.setServerType(e.target.value)}
          >
            <option value={""}>Select Server Type</option>
            {SERVER_TYPES.map(function (val, idx) {
              return (
                <option key={idx} value={val.value}>
                  {val.label}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </div>

      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Server IP:</Form.Label>
          )}
          <Form.Control
            type="text"
            id="server_ip"
            value={props.serverIP}
            placeholder="Server IP"
            onChange={(e) => props.setServerIP(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Server Name:</Form.Label>
          )}
          <Form.Control
            type="text"
            id="server_name"
            value={props.serverName}
            placeholder="Server Name"
            onChange={(e) => props.setServerName(e.target.value)}
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && <Form.Label htmlFor="status">OS:</Form.Label>}
          <Form.Control
            type="text"
            id="os"
            value={props.OS}
            placeholder="OS"
            onChange={(e) => props.setOS(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">CPU Cores:</Form.Label>
          )}

          <Form.Control
            type="text"
            id="cpu_cores"
            value={props.CPUCores}
            placeholder="CPU Cores"
            onChange={(e) => props.setCPUCores(e.target.value)}
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && <Form.Label htmlFor="status">RAM:</Form.Label>}

          <Form.Control
            type="text"
            id="ram"
            value={props.RAM}
            placeholder="RAM"
            onChange={(e) => props.setRAM(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Hard Disk:</Form.Label>
          )}

          <Form.Control
            type="text"
            id="hard_disk"
            value={props.hardDisk}
            placeholder="Hard Disk"
            onChange={(e) => props.setHardDisk(e.target.value)}
          />
        </Form.Group>
      </div>
    </>
  );
}
