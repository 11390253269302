import { Form } from "react-bootstrap";

export default function RenderUserCompanyFields(props) {
  return (
    <>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="company_name">Company Name:</Form.Label>
          )}
          <Form.Control
            key={"company_name"}
            type="text"
            id="company_name"
            placeholder="Company Name"
            maxLength={100}
            value={props.company_name}
            onChange={(e) => props.set_company_name(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="company_phone">Company phone:</Form.Label>
          )}
          <Form.Control
            type="tel"
            pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
            id="company_phone"
            placeholder="Company Phone"
            maxLength={15}
            value={props.company_phone}
            onChange={(e) => props.set_company_phone(e.target.value)}
            required
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="company_address">Company Address:</Form.Label>
          )}
          <Form.Control
            as="textarea"
            rows={3}
            id="company_address"
            placeholder="Company Address"
            maxLength={500}
            value={props.company_address}
            onChange={(e) => props.set_company_address(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="company_pincode">Company Pincode:</Form.Label>
          )}
          <Form.Control
            type="number"
            id="company_pincode"
            placeholder="Company Pincode"
            value={props.company_pincode}
            onChange={(e) => props.set_company_pincode(e.target.value)}
            required
          />
        </Form.Group>
      </div>
    </>
  );
}
