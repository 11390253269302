import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button,Table } from "react-bootstrap";
import Card from "../../../components/Card";
import ToastNotification from "../../../components/Toast";
import { API_MODULE_LIST ,API_ACL_SETTING_EDIT} from "../../../service/url";
import { postApiCall, getApiCall } from "../../../service/httpCall";
import { useNavigate, useParams } from "react-router-dom";
import {getUser,
  getUserModules,
} from "../../../utilities/setting";
import { USER_ROLES } from '../../../utilities/constant';
import { ROUTES } from "../../../utilities/constant";
import { useAuth, errorCheck } from '../auth/auth-utils';
import AsyncStorage from '@react-native-async-storage/async-storage';

function getApiUrl(url, id){
    return url.replace(':id', id);
}

const ACLSettingEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [aclName, setACLName] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [selectedModules, setSelectedModules] = useState({});
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modulePermission, updateModulePermission] = useState({});



  useEffect(() => {
    async function superadminCheck() {
      const user = JSON.parse(await AsyncStorage.getItem('user'));
      if (user?.module_operations) {
        updateModulePermission(JSON.parse(user?.module_operations));
      }
      if (user.role === USER_ROLES.SUPERADMIN) {
        setIsSuperAdmin(true);
      }
      setLoading(false); // Set loading to false after check
    }

    superadminCheck();
  }, []);
 
  useEffect(() => {

    const editApiUrl = getApiUrl(API_ACL_SETTING_EDIT, id);
    getApiCall(editApiUrl, {})
        .then(onGetSuccess).catch(onError);

}, [id]);

  useEffect(() => {
    if (!loading) { // Ensure superadminCheck has completed
      getApiCall(API_MODULE_LIST, {})
        .then(onModuleListSuccess)
        .catch(onError);
    }
  }, [loading]);


  useEffect(() => {
    checkAuth(setToastProps, setShowToast)
  }, []);

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);


  function onGetSuccess(resp){
    setACLName(resp.acl_name);
    setSelectedModules(JSON.parse(resp.module_operations));
    

}
  function getKeysWithTrueValues(moduleOperations) {
    return Object.keys(moduleOperations).filter(key => {
        const permissions = moduleOperations[key];
        return Object.values(permissions).some(value => value === true);
    });
  }

  function onModuleListSuccess(resp) {
    if (isSuperAdmin) {
      setModuleList(resp)
    } else {
      getUser().then((user) => {
        if (user) {
          if (user?.module_operations) {
            let modules = getKeysWithTrueValues(JSON.parse(user.module_operations));
             modules = resp.filter(item => modules.includes(item.module_name));
            setModuleList(modules);
          }
        }
      });
    }
  }

  function validateForm() {
    let valid = true;

    if (!aclName.trim()) {
      valid = false;
      setToastProps({ message: "ACL name required", type: "danger" });
    } 

    return valid;
  }

  async function submitForm() {
    setButtonDisable(true);

    if (!validateForm()) {
      setButtonDisable(false);
      return;
    }

    let params = { acl_name: aclName,
     module_operations: selectedModules
    };
    const editApiUrl = getApiUrl(API_ACL_SETTING_EDIT, id);
      postApiCall(editApiUrl, params)
         .then(onAClSettingUpdateSuccess)
         .catch(onAClSettingUpdateError);
}



  function onAClSettingUpdateSuccess(resp) {
    setToastProps({ message: "ACL Setting updated successfully", type: "success" });
    setTimeout(() => {
      navigate(`/${ROUTES.ACL_SETTING.LIST}`);
    }, 1500);
  }
  function onAClSettingUpdateError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
       errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
      setButtonDisable(false);
      setToastProps({message: JSON.stringify(err), type: 'danger'});
    }
 }

  
  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     setButtonDisable(false);
     setToastProps({message: JSON.stringify(err), type: 'danger'});
   }
 }


  const handleCheckboxChange = (moduleName, operation) => {
    if (isSuperAdmin) {
      setSelectedModules((prevModules) => ({
        ...prevModules,
        [moduleName]: {
          ...prevModules[moduleName],
          [operation]: !prevModules[moduleName][operation]
        }
      }));
    } else {
    if (modulePermission[moduleName][operation]) {
      setSelectedModules((prevModules) => ({
        ...prevModules,
        [moduleName]: {
          ...prevModules[moduleName],
          [operation]: !prevModules[moduleName][operation]
        }
      }));
    } else {
      setToastProps({ message: `Don't have Permission to add ${moduleName} ${operation} operation.`, type: "danger", time: 2000});
    }
  }
  };

  
  const getCheckboxStyle = (isChecked) => ({
    backgroundColor: isChecked ? 'green' : 'red',
    width: '20px',
    height: '20px',
    display: 'inline-block',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '20px',
    color: 'white'
  });

  return (
    <>
      <div>
        <Row>
          <Col xl="12" lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">ACL Setting Information</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-user-info">
                  <form>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="username">ACL Name:</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="ACL Name"
                          value={aclName}
                          onChange={(e) => setACLName(e.target.value)}
                        />
                      </Form.Group>
                      
                    </div>
                                                                            
                   <div className="header-title">
                  <h6 className="card-title">Please Select Module Operations</h6>
                   </div>
                   {moduleList.length === 0 ? (
                      <div>No modules added</div>
                    ) : (
                  <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>Module Name</th>
                      <th>Read</th>
                      <th>Add</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                  
          {moduleList.map((module, index) => (
            <tr key={index}>
              <td>{module.module_name}</td>
              <td>
                <div
                  style={getCheckboxStyle(selectedModules[module.module_name]?.read)}
                  onClick={() => handleCheckboxChange(module.module_name, 'read')}
                >
                  {selectedModules[module.module_name]?.read ? '✓' : 'X'}
                </div>
              </td>
              <td>
                <div
                  style={getCheckboxStyle(selectedModules[module.module_name]?.add)}
                  onClick={() => handleCheckboxChange(module.module_name, 'add')}
                >
                  {selectedModules[module.module_name]?.add ? '✓' : 'X'}
                </div>
              </td>
              <td>
                <div
                   style={getCheckboxStyle(selectedModules[module.module_name]?.edit)}
                  onClick={() => handleCheckboxChange(module.module_name, 'edit')}
                >
                  {selectedModules[module.module_name]?.edit ? '✓' : 'X'}
                </div>
              </td>
              <td>
                <div
                  style={getCheckboxStyle(selectedModules[module.module_name]?.delete)}
                  onClick={() => handleCheckboxChange(module.module_name, 'delete')}
                >
                   {selectedModules[module.module_name]?.delete ? '✓' : 'X'}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
                  </Table>
                    )}
          

                    <Button
                      onClick={async () => {
                        submitForm();
                      }}
                      type="button"
                      variant="btn btn-primary"
                      disabled={buttonDisable}
                    >
                      Update &nbsp;
                      {buttonDisable ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                    </Button>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
};

export default ACLSettingEdit;
