import React from 'react'
const Loader = () => {
    
    return (  
        <div className="d-flex justify-content-center align-items-center" style={{ height: '90vh' }}>
        <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
            <span className="visually-hidden">Loading...</span>
        </div>
        <div className="ms-3" style={{ fontSize: '1.5rem' }}>
            <strong>Loading...</strong>
        </div>
    </div>
   );

}   
    
export default Loader;