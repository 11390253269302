import { useState, forwardRef, useImperativeHandle } from 'react';
import Form from 'react-bootstrap/Form';

const RenderPlayActionModalBody = forwardRef((props, _ref) => {

    const [targetVal, setTargetVal] = useState(props.actionBodyContent.targetVal);
    const [label, setLabel] = useState(props.actionBodyContent.label);

    useImperativeHandle(_ref, () => ({
        getFormValues: () => {
            return {type: 'play', data: {targetVal, label} }
        }
    }));

    return (
        <Form>
            <Form.Group className="form-group">
                <Form.Label htmlFor="target">Target</Form.Label>
                <Form.Select id="target" aria-label="Select target" onChange={(e) => setTargetVal(e.target.value)}>
                    <option>Select target</option>
                    <option value="new_node">New Node</option>
                    {
                        props.nodeList.current.filter(val => {
                            return val.key != props.selectedKey  
                        })
                        .map(val => <option key={val.key} value={val.key}>{val.label}</option>)
                    }
                </Form.Select>
            </Form.Group>
            {
                targetVal == 'new_node'
                ?
                <Form.Group className="form-group">
                    <Form.Label htmlFor="label">Label</Form.Label>
                    <Form.Control type="text" maxLength={500} id="label" onChange={e => setLabel(e.target.value)} value={label} />
                </Form.Group>
                :
                <></>
            }
    </Form>);

});

export default RenderPlayActionModalBody;