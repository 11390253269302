import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button,Table } from "react-bootstrap";
import Card from "../../../components/Card";
import ToastNotification from "../../../components/Toast";
import { API_ACL_SETTING_LIST, API_USERS_PROFILE } from "../../../service/url";
import { postApiCall, getApiCall } from "../../../service/httpCall";
import { useNavigate, useParams } from "react-router-dom";
import { USER_STATUS } from "../../../utilities/constant";
import RenderUserCompanyFields from "../../../components/partials/dashboard/users/company";
import {getUser,
  getUserModules,
  getUserUpdateAccessRoleLabel,
  ifUserAssociateCompany,
} from "../../../utilities/setting";
import { validatePhone } from "../../../utilities/helpers";
import { ROUTES } from "../../../utilities/constant";
import { useAuth, errorCheck } from '../auth/auth-utils';
import { USER_ROLES } from '../../../utilities/constant';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Select from 'react-select';


const VALIDATION_MIN_PASSWORD_LENGTH = 6;

const UserAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPincode, setCompanyPincode] = useState("");
  const [companyId, setCompanyId] = useState(undefined);
  const [activeUserAccessRole, setActiveUserAccessRole] = useState(undefined);
  const [userAccessRoles, setUserAccessRoles] = useState([]);
  const [aclSettingList, setAClSettingList] = useState([]);
  const [selectedACL, setSelectedACL] = useState("");
  const [ifRenderCompanyFields, setIfRenderCompanyFields] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedUserType, setSelectedUserType] = useState("");

  useEffect(() => {
    async function superadminCheck() {
      const user = JSON.parse(await AsyncStorage.getItem('user'));
      if (user.role === USER_ROLES.SUPERADMIN) {
        setIsSuperAdmin(true);
      }
      setLoading(false);
    }
    superadminCheck();
  }, []);

 
  function getProfileApiUrl() {
    return API_USERS_PROFILE.replace(":id", id);
  }

  useEffect(() => {
    if (!loading) { // Ensure superadminCheck has completed
      getApiCall(API_ACL_SETTING_LIST, {})
        .then(onAClSettingListSuccess)
        .catch(onError);
    }
  }, [loading]);

  useEffect(() => {
    const profileApiUrl = getProfileApiUrl();
    getApiCall(profileApiUrl, {}).then(onProfileGetSuccess).catch(onError);
  }, [id]);


  useEffect(() => {
    checkAuth(setToastProps, setShowToast)
  }, []);

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    getUserUpdateAccessRoleLabel()
      .then((resp) => {
        if (resp) {
          setUserAccessRoles(resp);
          setActiveUserAccessRole(resp[0].value);
          const isUserAssociatedCompany = ifUserAssociateCompany(resp[0].value);
          setIfRenderCompanyFields(isUserAssociatedCompany);
        }
      })
      .catch(onError);
  }, []);

  function onProfileGetSuccess(resp) {
    //Set user details
    setUsername(resp.username);
    setStatus(resp.status);
    setName(resp.name);
    setEmail(resp.email);
    setSelectedACL(resp.acl_settings?._id);
    setSelectedUserType(resp.role)
    //Set company details
    const companyDetails = resp.companies?._id;
    if (companyDetails) {
      setCompanyName(companyDetails.name);
      setCompanyPhone(companyDetails.phone);
      setCompanyPincode(companyDetails.pincode);
      setCompanyAddress(companyDetails.address);
      setCompanyId(companyDetails["_id"]);
      // setCompanyId(companyDetails);

    }
    
  }


  function onAClSettingListSuccess(resp) {
    if (isSuperAdmin) {
      setAClSettingList(resp);
    } else {
      getUser().then((user) => {
        if (user) {
          if (user?.acl_settings) {
            setAClSettingList([user.acl_settings]);
          }
        }
      });
    }
  }


  function validateForm() {
    let valid = true;

    if (!username.trim()) {
      valid = false;
      setToastProps({ message: "Username required", type: "danger" });
    } else if (!status || isNaN(status)) {
      valid = false;
      setToastProps({ message: "Status required", type: "danger" });
    } else if (password.trim() && confirmPassword.trim()) {
      if (
        password.trim().length < VALIDATION_MIN_PASSWORD_LENGTH ||
        confirmPassword.trim().length < VALIDATION_MIN_PASSWORD_LENGTH
      ) {
        valid = false;
        setToastProps({
          message: `Password and confirm password must be of minimum ${VALIDATION_MIN_PASSWORD_LENGTH} characters`,
          type: "danger",
        });
      } else if (password.trim() != confirmPassword.trim()) {
        valid = false;
        setToastProps({
          message: "Password and confirm password not matching",
          type: "danger",
        });
      }
    } else if (ifRenderCompanyFields) {
      valid = validateCompanyFields();
    }

    if (isSuperAdmin) {
      if (!selectedUserType.trim()) {
        valid = false;
        setToastProps({ message: "UserType required", type: "danger" });
      }
    }

    return valid;
  }

  async function submitForm() {
    setButtonDisable(true);

    if (!validateForm()) {
      setButtonDisable(false);
      return;
    }

    const companyParams = {
      name: companyName,
      phone: companyPhone,
      pincode: companyPincode,
      address: companyAddress,
      id: companyId,
    };
    const params = { username, name, email, status, company: companyParams,
      acl_settings: (selectedACL === "") ? null: selectedACL
    };

    params.role = (isSuperAdmin) ? selectedUserType : activeUserAccessRole

    if (password) {
      params["password"] = password;
    }

    const profileApiUrl = getProfileApiUrl();
    postApiCall(profileApiUrl, params).then(onUserUpdateSuccess).catch(onError);
  }

  function onUserUpdateSuccess(resp) {
    setToastProps({ message: "User updated successfully", type: "success" });
    setTimeout(() => {
      navigate(`/${ROUTES.USERS.LIST}`);
    }, 1500);
  }

  
  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     setButtonDisable(false);
     setToastProps({message: JSON.stringify(err), type: 'danger'});
   }
 }

  function RenderUserStatusOptions() {
    let arrStatusOptions = [<option key="select-status" value="">Select status</option>];

    USER_STATUS.map((val,idx) => {
      arrStatusOptions.push(<option key={idx} value={val.value}>{val.label}</option>);
    });

    return arrStatusOptions;
  }

  function validateCompanyFields() {
    let valid = true;
    if (!companyName?.trim()) {
      valid = false;
      setToastProps({ message: "Company name required", type: "danger" });
    } else if (!validatePhone(companyPhone)) {
      valid = false;
      setToastProps({
        message: "Company phone invalid format",
        type: "danger",
      });
    } else if (!companyPincode?.trim()) {
      valid = false;
      setToastProps({ message: "Company pincode required", type: "danger" });
    } else if (!companyAddress?.trim()) {
      valid = false;
      setToastProps({ message: "Company address required", type: "danger" });
    }

    return valid;
  }

  return (
    <>
      <div>
        <Row>
          <Col xl="12" lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">User Information</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-user-info">
                  <form>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="username">User Name:</Form.Label>
                        <Form.Control
                          type="text"
                          id="username"
                          placeholder="User Name"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="name">Full Name:</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          maxLength={100}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="email">Email:</Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          maxLength={100}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="status">Status:</Form.Label>
                        <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          className="form-select p-2"
                          id="status"
                        >
                          <RenderUserStatusOptions />
                        </select>
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="pass">Password:</Form.Label>
                        <Form.Control
                          type="password"
                          id="pass"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                      {/* <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="rpass">
                          Confirm Password:
                        </Form.Label>
                        <Form.Control
                          type="password"
                          id="rpass"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </Form.Group> */}
                       <Form.Group className="col-md-6 form-group">
                       <Form.Label htmlFor="aclSetting">ACL Setting:</Form.Label>
                          <Form.Select
                            id="aclSetting"
                            required
                            value={selectedACL}
                            className="p-2"
                            onChange={(e) => setSelectedACL(e.target.value)}
                          >
                            <option value={""}>ACL Setting</option>
                            {aclSettingList.map(function (val, idx) {
                              return (
                                <option key={idx} value={val._id}>
                                  {val.acl_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                    </div>

                    <RenderUserCompanyFields
                      isEditMode={true}
                      company_name={companyName}
                      company_phone={companyPhone}
                      company_pincode={companyPincode}
                      company_address={companyAddress}
                      set_company_name={setCompanyName}
                      set_company_phone={setCompanyPhone}
                      set_company_pincode={setCompanyPincode}
                      set_company_address={setCompanyAddress}
                    />
          
                    {(isSuperAdmin ) && (
                      <div className="row">
                        <Form.Group className="col-md-6 form-group">
                          <Form.Select
                            id="userType"
                            required
                            value={selectedUserType}
                            className="p-2"
                            onChange={(e) => setSelectedUserType(e.target.value)}
                          >
                            <option value="" disabled hidden>
                              UserType
                            </option>
                            <option key="1" value="role_reseller">
                              Reseller
                            </option>
                            <option key="2" value="role_sub_superadmin">
                              Sub-Superadmin
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                      )}
                    <Button
                      onClick={async () => {
                        submitForm();
                      }}
                      type="button"
                      variant="btn btn-primary"
                      disabled={buttonDisable}
                    >
                      Update &nbsp;
                      {buttonDisable ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                    </Button>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
};

export default UserAdd;
