import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import ToastNotification from '../../../components/Toast';
import { API_CALL_CENTRE_CREATE, API_COUNTRY_CODES_LIST, API_TIMEZONES_LIST } from '../../../service/url';
import { postApiCall } from '../../../service/httpCall';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utilities/constant';
import { getApiCall } from '../../../service/httpCall';
import { useAuth, errorCheck } from '../auth/auth-utils';

const CallCentreCreate = () => {
   const { checkAuth } = useAuth();
   const navigate = useNavigate();

   const [name, setName] = useState('');
   const [domain, setDomain] = useState('');
   const [description, setDescription] = useState('');
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);
   const [countryCodeList, setCountryCodeList] = useState([]);
   const [countryCode, setCountryCode] = useState('');
   const [timezonesList, setTimezonesList] = useState([]);
   const [timezone, setTimezone] = useState('');

   useEffect( () => {

      getApiCall(API_COUNTRY_CODES_LIST, {})
         .then(onCountryCodeListSuccess)
         .catch(onError);

      getApiCall(API_TIMEZONES_LIST, {})
         .then(onTimezonesListSuccess)
         .catch(onError);         

   }, []);

   useEffect(() => {
      checkAuth(setToastProps, setShowToast)
    }, []);

   useEffect(() => {

      if (toastProps) {
         setShowToast(true);
      }

   }, [toastProps]);

   function onCountryCodeListSuccess(resp) {
      setCountryCodeList(resp);
   }


   function onTimezonesListSuccess(resp){
      setTimezonesList(resp);
   }


   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        console.log(err)
      }
   }

   function validateForm() {

      let valid = true;

      if (!name.trim()) {
         valid = false;
         setToastProps({ message: 'Name required', type: 'danger' });
      }
      else if (!domain.trim()) {
         valid = false;
         setToastProps({ message: 'Domain required', type: 'danger' });
      }
      else if (!description.trim()) {
         valid = false;
         setToastProps({ message: 'Description required', type: 'danger' });
      }
      else if (!countryCode.trim()) {
         valid = false;
         setToastProps({ message: 'Country Code required', type: 'danger' });
      }
      else if (!timezone.trim()) {
         valid = false;
         setToastProps({ message: 'Timezone required', type: 'danger' });
      }

      return valid;

   }

   async function submitForm() {

      setButtonDisable(true);

      if (!validateForm()) {
         setButtonDisable(false);
         return;
      }

      const params = { name, domain, description, countryCode, timezone }

      postApiCall(API_CALL_CENTRE_CREATE, params)
         .then(onCreateCreateSuccess)
         .catch(onCreateCreateError);

   }

   function onCreateCreateSuccess(resp) {
      setToastProps({ message: 'Call Centre created successfully', type: 'success' });
      setTimeout(() => {
         navigate(`/${ROUTES.CALL_CENTRES.LIST}`);
      }, 1500);
   }

   function onCreateCreateError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        setButtonDisable(false);
        setToastProps({message: JSON.stringify(err), type: 'danger'});
      }
   }

   return (
      <>
         <div>
            <Row>
               <Col xl="12" lg="12">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">New Call Centre Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div className="new-user-info">
                           <form>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="name">Name:</Form.Label>
                                    <Form.Control type="text" id="name" maxLength={500} value={name} onChange={e => setName(e.target.value)} required />
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="domain">Domain:</Form.Label>
                                    <Form.Control type="text" id="domain" maxLength={500} value={domain} onChange={e => setDomain(e.target.value)} required />
                                 </Form.Group>
                              </div>
                              <div className="row">
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="description">Description:</Form.Label>
                                    <Form.Control as="textarea" rows={3} id="description" maxLength={500} value={description} onChange={e => setDescription(e.target.value)} required />
                                 </Form.Group>
                              </div>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="countryCode">Select Country Code:</Form.Label>
                                    <Form.Select id="countryCode" required value={countryCode} onChange={e => setCountryCode(e.target.value)}>
                                       <option value={''}>Select</option>
                                       {
                                          countryCodeList.map(function (val, idx) {
                                             return (<option key={idx} value={val._id}>{val.name}</option>)
                                          })
                                       }
                                    </Form.Select>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="timezone">Select Timezone</Form.Label>
                                    <Form.Select id="timezone" required value={timezone} onChange={e => setTimezone(e.target.value)}>
                                       <option value={''}>Select</option>
                                       {
                                          timezonesList.map(function (val, idx) {
                                             return (<option key={idx} value={val._id}>{val.value}</option>)
                                          })
                                       }
                                    </Form.Select>   
                                 </Form.Group>
                              </div>
                              <hr />
                              <Button onClick={submitForm} type="button" variant="btn btn-primary" disabled={buttonDisable}>
                                 Create New Call Centre &nbsp;
                                 {
                                    buttonDisable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>
                                 }
                              </Button>
                           </form>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>

         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

      </>
   )

}

export default CallCentreCreate;