import React, { useState, useEffect, useRef } from 'react';
import { Treant } from 'treant-js';
import "treant-js/Treant.css";
import Raphael from "raphael";
import '../../../assets/custom/css/treant.css';
import AddNodeModal from './_addNodeModal';
import RenderPlayActionModalBody from './_playActionModalBody';
import RenderInputActionModalBody from './_inputActionModalBody';
import Form from 'react-bootstrap/Form';
import '@fortawesome/fontawesome-free/css/all.css';
import { postApiCall } from '../../../service/postApiCall';
import { API_IVR_CREATE } from '../../../service/url';
import ToastNotification from '../../../components/Toast';
import { ROUTES } from '../../../utilities/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, errorCheck } from '../auth/auth-utils';

window.Raphael = Raphael;

const chartConfig = {
    chart: {
        container: "#ivr-container",
        connectors: {
            type: 'curve'
        },
        node: {
            HTMLclass: 'nodeExample1',
            drawLineThrough: true
        }
    },
    nodeStructure: {
        innerHTML: nodeHTMLStructure('root'),
        key: 'root',
        action_id: 0,
        audio_id: 0,
        children: []
    }
};

function _getAndUpdateNodeStructureByKey(key, nodes, data){

    for(let idx in nodes){

        if(nodes[idx]['key'] == key){

            if(data.hasOwnProperty('action_id')) nodes[idx].action_id = data['action_id'];
            else if(data.hasOwnProperty('audio_id')) nodes[idx].audio_id = data['audio_id'];
            if(data.hasOwnProperty('children')) nodes[idx].children.push(data['children']);
            nodes[idx].innerHTML = nodeHTMLStructure(key, nodes[idx]);

            break;

        }else{

            _getAndUpdateNodeStructureByKey(key, nodes[idx].children, data);

        }

    }

}

function getAndUpdateNodeStructureByKey(key, config, data){

    if(config.nodeStructure.key == key){
    
        const nodeData = config.nodeStructure;
        if(data.hasOwnProperty('action_id')) nodeData.action_id = config.nodeStructure.action_id = data['action_id'];
        else if(data.hasOwnProperty('audio_id')) config.nodeStructure.audio_id = data['audio_id'];
        if(data.hasOwnProperty('children')) nodeData.children.push(data['children']);
        config.nodeStructure.innerHTML = nodeHTMLStructure(key, nodeData);
    
    }else{
     
        _getAndUpdateNodeStructureByKey(key, config.nodeStructure.children, data);

    }

    return config;

}

//Check childrens
function _getNodeStructureByKey(key, nodes){

    let resultNode = null;

    for(let val of nodes){

        if(val.key == key){
            resultNode = val;
            break;
        }else{
            resultNode = _getNodeStructureByKey(key, val.children);
        }

    }

    return resultNode;

}

function getNodeStructureByKey(key, config){

    if(config.nodeStructure.key == key){
        return config.nodeStructure;
    }

    return _getNodeStructureByKey(key, config.nodeStructure.children);

}

function _getChildNodesByKey(nodes){

    let childNodeKeys = [];

    for(let elem of nodes){

        childNodeKeys.push(elem.key);
        _getChildNodesByKey(elem.children);

    }

    return childNodeKeys;

}

function getChildNodesByKey(key, nodes){

    let childNodeKeys = [];

    for(let elem of nodes){

        if(elem.key == key){
            childNodeKeys = _getChildNodesByKey(elem.children);
            break;
        }

    }

    return childNodeKeys;

}

function nodeHTMLStructure(key, data){

    let actionSelectedPlay = '', actionSelectedInput = '', actionSelectedHangup = '';
    const action_id = data?.action_id;
    const audio_id = Number(data && data.audio_id ? data.audio_id: 0);

    switch(action_id){
        case 0:
            actionSelectedPlay = 'selected';
            break;
        case 1:
            actionSelectedInput = 'selected';
            break;
        case 2:
            actionSelectedHangup = 'selected';
            break;
        default:
            actionSelectedPlay = 'selected';
    }

    const removeNodeHtml = (key != 'root') ? `<i title="Remove" key="${key}" class="fa fa-window-close remove-node float-right"></i>` : ``;

    let htmlStr = `<div class="_treeLeaf d_f">
    <div class="t_Data d_f">
        <p class="shortName">
            <div>
                <div class="CardNodeStyle">
                    <div>
                        ${removeNodeHtml}
                    </div>
                    <div class="list-group list-group-flush">
                        <div class="list-group-item">
                            <select aria-label="Select action" class="form-select select-action" key="${key}">
                                <option value="0" ${actionSelectedPlay}>Play</option>
                                <option value="1" ${actionSelectedInput}>Input</option>
                                <option value="2" ${actionSelectedHangup}>Hangup</option>
                            </select>
                        </div>
                        <div class="list-group-item">`;

    if(action_id != 2){
        htmlStr = htmlStr.concat(`
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="Node_Play_Icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.5 16.5v-9l7 4.5-7 4.5z"></path></svg>
        <select class="form-select select-audio" key="${key}">
            <option value="0" ${Boolean(audio_id == 0) ? 'selected': ''}>Select audio</option>
            <option value="1" ${Boolean(audio_id == 1) ? 'selected': ''}>Audio 1</option>
            <option value="2" ${Boolean(audio_id == 2) ? 'selected': ''}>Audio 2</option>
            <option value="3" ${Boolean(audio_id == 3) ? 'selected': ''}>Audio 3</option>
        </select>
        `);   
    }                 

    htmlStr = htmlStr.concat(`
                        </div>
                        <div class="list-group-item">`);

    if(action_id != 2){
        htmlStr = htmlStr.concat(`<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" key="${key}" class="Node_Add_Icon add-node" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><title>Add or edit node</title><path key="${key}" fill="none" d="M0 0h24v24H0z"></path><path key="${key}" d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z"></path></svg>`);
    }

    htmlStr = htmlStr.concat(`</div></div></div></div></p></div></div>`);

    return htmlStr;

}


function circleNodeHTMLStructure(key){
    return `<div class="circle blue">${key}</div>`;
}


function validateAddNode(key, config){

    if(config.nodeStructure.key == key && config.nodeStructure.action_id == 0){
        return Boolean(config.nodeStructure.children.length == 0);
    }

    return true;

}


export default function IvrCreate(){

    const navigate = useNavigate();
    const {cid} = useParams();

    const [config, setConfig] = useState(chartConfig);
    const [showAddNodeModal, setShowAddNodeModal] = useState(false);
    const [selectedActionId, setSelectedActionId] = useState(undefined);
    const [selectedKey, setSelectedKey] = useState(undefined);
    const keyCount = useRef(0);
    const [actionBodyContent, setActionBodyContent] = useState(null);
    const nodeList = useRef([ {key: 'root', label: 'root', sms_api_url: ''} ]);
    const [ivrName, setIvrName] = useState('');
    const [ivrMenuTime, setIvrMenuTime] = useState('');
    const [prompt, setPrompt] = useState('');
    const [toastProps, setToastProps] = useState(null);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const { checkAuth } = useAuth();

    useEffect( () => {

        if(toastProps){
           setShowToast(true);
        }
  
     }, [toastProps]);

     
    useEffect(() => {
        checkAuth(setToastProps, setShowToast)
    }, []);

    useEffect( () => {

        document.addEventListener('change', (e) => addEventListenerOnChange(e, config));
        document.addEventListener('click', e => addEventListenerOnClick(e, config));

        function addEventListenerOnClick(e, config){

            if(e.target.getAttribute('key') != undefined){
                const targetNode = (e.target.tagName == 'path') ? e.target.parentNode : e.target;
                const key = targetNode.getAttribute('key');
                if(targetNode.className?.baseVal?.includes('add-node')  || targetNode.className.includes('add-node')){
                    if(validateAddNode(key, config)){
                        const node = getNodeStructureByKey(key, config);
                        if(node){
                            setSelectedActionId(node.action_id);
                            setSelectedKey(key);
                            setShowAddNodeModal(true);
                            if(node.action_id == 0){
                                //const target = node.children?[0]?.
                                setActionBodyContent({ targetVal: '', label: '' })
                            }
                        }
                    }else{
                        alert('Cannot add more than one children');
                    }
                }
                else if(targetNode.className.includes('remove-node')){

                    if(window.confirm('Are you aure you want to delete it?')){
                        removeNode(key, config.nodeStructure);
                        reRenderChart(config);
                    }

                }
            }
        
        }

        new Treant(config);

        //Remove event listeners
        return () => {
            document.removeEventListener('change', addEventListenerOnChange);
            document.removeEventListener('click', addEventListenerOnClick);
        }
    
    }, [config]);


    function removeNode(key, nodes){

        let removeNodeIdx = -1;
    
        for(let idx in nodes.children){
    
            if(nodes.children[idx]['key'] == key){
                removeNodeIdx = idx;
                break;
            }else{
                removeNode(key, nodes.children[idx]);
                //removeNodeList(key, nodes);
            }
    
        }
    
        if(removeNodeIdx > -1){
            nodes.children.splice(removeNodeIdx, 1);
        }
    
    }


    function removeNodeList(key, nodes){
        const childNodesByKey = getChildNodesByKey(key, nodes.children);
        console.log(childNodesByKey);
        nodeList.current = nodeList.current.filter(elem => elem.key != key);
    }


    function addEventListenerOnChange(e, config){

        if(e.target.className.includes('select-action')){
            const key = e.target.getAttribute('key');
            const val = Number(e.target.value);
            const updatedConfig = getAndUpdateNodeStructureByKey(key, config, {'action_id': val});
            reRenderChart(config);
        }
        else if(e.target.className.includes('select-audio')){
            const key = e.target.getAttribute('key');
            const val = Number(e.target.value);
            const updatedConfig = getAndUpdateNodeStructureByKey(key, config, {'audio_id': val});
            reRenderChart(config);
        }
    
    }


    function reRenderChart(config){

        document.getElementById('ivr-container').innerHTML = '';
        new Treant(config);
        setShowAddNodeModal(false);
        setSelectedKey(undefined);
    
    }    


    function createAndGetNewNode(){
        const newKey = keyCount.current+1;
        const newStructure = {
            innerHTML: nodeHTMLStructure(newKey),
            key: newKey,
            action_id: 0,
            audio_id: 0,
            children: []
        }
        keyCount.current = newKey;
        return newStructure;
    }

    function createAndGetNewCircularNode(label){
        const newKey = keyCount.current+1;
        const newStructure = {
            HTMLclass: 'circle-container',
            stackChildren: true,
            innerHTML: circleNodeHTMLStructure(label),
            key: newKey,
            action_id: 0,
            audio_id: 0,
            children: []
        }
        keyCount.current = newKey;
        return newStructure;
    }

    function addPlayActionNodeChildren(bodyContent){

        if(selectedKey != undefined){
   
            if(bodyContent.targetVal == 'new_node'){
                var newStructure = createAndGetNewNode();
            }else{
                var newStructure = createAndGetNewCircularNode(bodyContent.targetVal);
            }
            const newConfig = getAndUpdateNodeStructureByKey(selectedKey, config, {'children': newStructure});
            nodeList.current = [...nodeList.current, {key: keyCount.current, label: bodyContent.label?bodyContent.label:keyCount.current}]
            reRenderChart(newConfig)
    
        }
    
    }


    function addInputActionNodeChildren(bodyContent){

        if(selectedKey != undefined){
    
            var newConfig = config;

            for(let val of bodyContent){

                if(val.target == 'new_node'){
                    var newStructure = createAndGetNewNode();
                }else{
                    var newStructure = createAndGetNewCircularNode(val.target);
                }
                newConfig = getAndUpdateNodeStructureByKey(selectedKey, config, {'children': newStructure});
                nodeList.current = [...nodeList.current, {key: keyCount.current, label: val.label?val.label:keyCount.current, sms_api_url: val.sms_api_url}]

            }
            
            reRenderChart(newConfig);

        }

    }


    function updateActionBodyContent(resp){
        setActionBodyContent(resp.data);
        if(resp.type == 'play') addPlayActionNodeChildren(resp.data);
        else if(resp.type == 'input') addInputActionNodeChildren(resp.data);
    }

    function submitIvr(e){

        e.preventDefault();
        setButtonDisable(true);

        const nodeStructureObj = config.nodeStructure; 
        const rootChildrenObj = nodeStructureObj.children.reduce( (init, val) => {
            init[val.key] = `root_${val.key}`;
            return init;
        }, {});

        const finalObj = { 
            [`root_${nodeStructureObj.key}`]: { action: nodeStructureObj.action_id, audio: nodeStructureObj.audio_id, node: nodeStructureObj.key, parent: 'root', next: rootChildrenObj } 
        }

        function repeat(node, parent_key){

            for(let val of node){

                const rootChildrenObj = val.children.reduce( (init, val) => {
                    init[val.key] = `root_${val.key}`;
                    return init;
                }, {});
                finalObj[`root_${val.key}`] = { action: val.action_id, audio: val.audio_id, node: val.key, parent: parent_key, next: rootChildrenObj }

                repeat(val.children, val.key)
            }

        }

        repeat(nodeStructureObj.children, nodeStructureObj.key);

        const params = {'data': JSON.stringify(finalObj), 'name': ivrName, 'menu_wait_time': ivrMenuTime, 'reprompt': prompt, 'input_action_data': JSON.stringify(nodeList.current), 'config': JSON.stringify(config)}
        const apiIvrCreateUrl = API_IVR_CREATE.replace(':cid', cid);
        postApiCall(apiIvrCreateUrl, params)
        .then(resp => {
            setToastProps({message: 'Created successfully', type: 'success'});
            setTimeout( () => {
               navigate(`/${ROUTES.IVR.LIST.replace(':cid', cid)}`);
            }, 1500);
        })
        .catch(err => {
            if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
                errorCheck(err, navigate, setToastProps, setShowToast)
             } else {
               setButtonDisable(false);
               setToastProps({message: JSON.stringify(err), type: 'danger'});
             }
        });

    }

    return (<>
        <Form onSubmit={submitIvr}>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-sm-2">
                        <div>
                            <Form.Control required type="text" name="ivrName" placeholder="IVR Name" value={ivrName} onChange={e => setIvrName(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <Form.Select required className="form-select" aria-label="Select wait time" value={ivrMenuTime} onChange={e => setIvrMenuTime(e.target.value)}>
                            <option value="">Menu Wait Time</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </Form.Select>
                    </div>
                    <div className="col-sm-2">
                        <Form.Group className="form-group">
                            <Form.Select required className="form-select" aria-label="Default select example" value={prompt} onChange={e => setPrompt(e.target.value)}>
                                <option value="">RePrompt</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-sm-2">
                        <button type="submit" className="btn btn-primary" disabled={buttonDisable}>Submit</button>
                    </div>
                </div>
            </div>
        </Form>
        
        <div className="chart" id="ivr-container" style={{width: '100%', height: '100vh'}}></div>
        
        <AddNodeModal show={showAddNodeModal} setShow={setShowAddNodeModal} body={selectedActionId == 0 ? RenderPlayActionModalBody: RenderInputActionModalBody} actionBodyContent={actionBodyContent} updateActionBodyContent={updateActionBodyContent} nodeList={nodeList} selectedKey={selectedKey} />
        
        <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />
    
    </>)

}