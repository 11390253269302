import React, { useState, useEffect } from "react";
import Card from "../../../components/Card";
import { Row, Col, Button } from "react-bootstrap";

import ToastNotification from "../../../components/Toast";
import { postApiCall, getApiCall } from "../../../service/httpCall";
import { API_NUMBER_EDIT } from "../../../service/url";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../utilities/constant";
import RenderNumberForm from "./_form_update";
import { useAuth, errorCheck } from '../auth/auth-utils';

function getApiUrl(url, id) {
  return url.replace(":id", id);
}

export default function NumbersEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [DID, setDID] = useState("");
  const [countryCodeId, setCountryCodeId] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [numberType,setNumberType] = useState("");
  const [category,setCategory] =useState("");
  const [currency,setCurrency] = useState("");
  const [cost,setCost] = useState("");
  const [operator,setOperator] = useState("");
  const [status,setStatus] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    checkAuth(setToastProps, setShowToast)
  }, []);

  useEffect(() => {
    const editApiUrl = getApiUrl(API_NUMBER_EDIT, id);
    getApiCall(editApiUrl, {}).then(onGetSuccess).catch(onError);
  }, [id]);

  function onGetSuccess(resp) {
    setDID(resp.actual_number || "");
    setStateCode(resp.state_code || "");
    setCountryCodeId(resp.country_code || "");
    setCost(resp.cost || "")
    setNumberType(resp.number_type || "");
    setCategory(resp.category || "");
    setCurrency(resp.currency || "");
    setOperator(resp.operator || "");
    setStatus(resp.status || "");
  }
  

  function validateForm() {

    let valid = true;

    if (!countryCodeId) {
        valid = false;
        setToastProps({ message: 'Country Code required', type: 'danger' });
    }
    else if (!numberType) {
        valid = false;
        setToastProps({ message: 'Number Type is required', type: 'danger' });
    } else if (numberType === 'DID' && !category) {
        valid = false;
        setToastProps({ message: 'Category is required for DID', type: 'danger' });
    } else if (!currency) {
        valid = false;
        setToastProps({ message: 'Currency is required', type: 'danger' });
    }
    else if (!DID) {
       valid = false;
       setToastProps({ message: 'DID is required', type: 'danger' });
    } else if (!cost) {
        valid = false;
        setToastProps({ message: 'Cost is required', type: 'danger' });
    } else if (!operator) {
        valid = false;
        setToastProps({ message: 'Operator is required', type: 'danger' });
    } else if (numberType === 'DID' && !stateCode) {
        valid = false;
        setToastProps({ message: 'State Code is required for DID', type: 'danger' });
    } else if (!status) {
      valid = false;
      setToastProps({ message: 'Status is required', type: 'danger' });
  }
    return valid;

}

  async function submitForm(e) {
    e.preventDefault();

    setButtonDisable(true);

    if (!validateForm()) {
      setButtonDisable(false);
      return;
    }

    let params = {
      numberType: numberType,
      currency: currency,
      countryCode: countryCodeId,
      DID: DID,
      cost: cost,
      operator: operator,
      status: status
    };

    if (numberType === 'DID') {
        params.category = category;
        params.stateCode = stateCode; 
    } 

    const editApiUrl = getApiUrl(API_NUMBER_EDIT, id);
    postApiCall(editApiUrl, params)
      .then(onNumberUpdateSuccess)
      .catch(onError);
  }

  function onNumberUpdateSuccess(resp) {
    setToastProps({ message: "Number updated successfully", type: "success" });
    setTimeout(() => {
      navigate(`/${ROUTES.NUMBERS.LIST}`);
    }, 1500);
  }

  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     setButtonDisable(false);
     setToastProps({message: JSON.stringify(err), type: 'danger'});
   }
 }

  return (
    <>
      <div>
        <Row>
          <Col xl="12" lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Update Number Information</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-number-info"></div>

                <form
                  onSubmit={async (e) => {
                    submitForm(e);
                  }}
                >
                  <RenderNumberForm
                    DID={DID}
                    setDID={setDID}
                    countryCodeId={countryCodeId}
                    setCountryCodeId={setCountryCodeId}
                    stateCode={stateCode}
                    setStateCode={setStateCode}
                    cost={cost}
                    setCost={setCost}
                    numberType={numberType}
                    setNumberType={setNumberType}
                    category={category}
                    setCategory={setCategory}
                    currency={currency}
                    setCurrency={setCurrency}
                    operator={operator}
                    setOperator={setOperator}
                    status={status}
                    setStatus={setStatus}
                    buttonDisable={buttonDisable}
                    setButtonDisable={setButtonDisable}
                    toastProps={toastProps}
                    setToastProps={setToastProps}
                  />

                  <Button
                    type={"submit"}
                    variant="btn btn-primary"
                    disabled={buttonDisable}
                  >
                    Update Number &nbsp;
                    {buttonDisable ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <></>
                    )}
                  </Button>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
}
