import React, { useState, useEffect, useRef } from 'react'
import {Row,Col,Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Card from '../../../components/Card'
import { API_USERS_LIST, API_USERS_PROFILE, API_SWITCH_USER} from '../../../service/url';
import { getApiCall, deleteApiCallWithHeader } from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { getUserReadAccessRoleLabel, getUser } from '../../../utilities/setting';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import { ROUTES, USERS_ROLE } from '../../../utilities/constant';
import Loader  from '../../../components/partials/components/loader';
import { useNavigate } from "react-router-dom";
import { useAuth, errorCheck } from '../auth/auth-utils';
import switchUserIcon from '../../../assets/images/icons/switch-user.png';
import { switchUser } from '../../../utilities/setting';
import { USER_ROLES } from '../../../utilities/constant';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { postApiCall} from "../../../service/httpCall";
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { API_USER_DELETE } from "../../../service/url";
import "../../../assets/custom/css/custom.css";
import exportData from '../../../utilities/export';

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';

const UserList = () =>{
   const navigate = useNavigate();
   const { checkAuth } = useAuth();
   const [userlist, setUserlist] = useState([]);
   const [activeUserAccessRole, setActiveUserAccessRole] = useState(undefined)
   const [userAccessRoles, setUserAccessRoles] = useState([]);
   const [filters, setFilters] = useState({
      username: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      company_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
   });
   const statuses = useRef(['Active', 'Inactive']);
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [deleteUserId, setDeleteUserId] = useState(undefined);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [loading, setLoading] = useState(false);
   const [isSuperAdmin, checkIsSuperAdmin] = useState(false);
   const [selectedUsers, setSelectedUsers] = useState([]);
   const [isMultiDelete, setIsMultiDelete] = useState(false);
   const [buttonDisable, setButtonDisable] = useState(false);
   const [moduleOperations, setModuleOperations] = useState({});
   const [userRole, setUserRole] = useState('');
   


  useEffect(() => {
   getUser().then((user) => {
     if (user) {
       if (user.role === USER_ROLES.SUPERADMIN) {
         checkIsSuperAdmin(true);
       }
       if (user?.acl_settings) {
         setUserRole(user?.role)
         setModuleOperations(JSON.parse(user.acl_settings.module_operations));
       }
     }
   });
 }, []);


   const handleExport = async () => {
      setButtonDisable(true); 
      await exportData('Users', setToastProps, setShowToast);
      setButtonDisable(false); 
   };

   const handleCheckboxToggle = (userId) => {
      const isSelected = selectedUsers.includes(userId);
      setSelectedUsers(isSelected
          ? selectedUsers.filter(id => id !== userId)
          : [...selectedUsers, userId]);
  };

  const handleMultiDelete = () => {
        setIsMultiDelete(true);
        setEnableDeleteModal(true);
  };
    
   useEffect( () => {

      if(toastProps){
         setShowToast(true);
      }

   }, [toastProps]);

   useEffect( () => {

      async function superadminCheck () {
         const user = JSON.parse(await AsyncStorage.getItem('user'));
         if (user.role === USER_ROLES.SUPERADMIN) {
            checkIsSuperAdmin(true)
         }
      }

      superadminCheck();

   }, []);


   useEffect( () => {

      getUserReadAccessRoleLabel().then(resp => {
         if(resp){
            setUserAccessRoles(resp);
            setActiveUserAccessRole(resp[0].value);
         }
      })
      .catch(onError);

   }, []);


   useEffect(() => {
      if (activeUserAccessRole) {
          setLoading(true);
          getApiCall(API_USERS_LIST, { role: activeUserAccessRole })
              .then(onUserListSuccess)
              .catch(onError)
              .finally(() => setLoading(false));
      }
  }, [activeUserAccessRole]);

   
   function onUserListSuccess(resp){
      const updatedList = resp.map( (val, idx) => {
         val['key'] = idx+1;
         val['company_name'] = val.companies?.name;
         // val['actions'] = RenderActionComponent(val._id);
         val['createdAt'] = new Date(val['createdAt']).toGMTString();
         return val;
      } );
      setUserlist(updatedList);
   }

   async function handleDeleteUser(id){
      checkAuth(setToastProps, setShowToast)
      setIsMultiDelete(false);
      setEnableDeleteModal(true);
      setDeleteUserId(id);
   }

   const handleSelectAll = () => {
      if (selectedUsers.length === userlist.length) {
          setSelectedUsers([]);
      } else {
          const allUserIds = userlist.map(user => user._id);
          setSelectedUsers(allUserIds);
      }
  };

   useEffect( () => {
      if (confirmDelete) {
         if (isMultiDelete) {
             postApiCall(API_USER_DELETE, { userIds: selectedUsers })
                 .then(onUserDeleteSuccess)
                 .catch(onUserDeleteFail);
         } else {
             postApiCall(API_USER_DELETE, { userIds: [deleteUserId] })
                 .then(onUserDeleteSuccess)
                 .catch(onUserDeleteFail);
         }
     }
   }, [confirmDelete]);


   function onUserDeleteSuccess(resp){
      setToastProps({type: 'success', message: 'User deleted successfully'});
      let updatedList;
      if (isMultiDelete) {
         updatedList = userlist.filter(val => !selectedUsers.includes(val._id));
         setSelectedUsers([]);
      } else {
         updatedList = userlist.filter(val => val._id != deleteUserId);
      }
      setUserlist(updatedList);
      setEnableDeleteModal(false);
      setDeleteUserId(undefined);
      setConfirmDelete(false);
   }

   function onUserDeleteFail(err){
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
       } else {
      console.log(err);
      setToastProps({type: 'danger', message: `Unable to delete user: ${err}`});
       }
       setConfirmDelete(false); 
   }

   async function switchAccount(id){
      postApiCall(API_SWITCH_USER, { id }).then(onAccountSwitchSuccess).catch(onAccountSwitchError);
   }

   async function onAccountSwitchSuccess(response) {
      const userData = response;
      const switchAccountSuccess = await switchUser(userData);
      if(switchAccountSuccess){
         setToastProps({type: 'success', message: `Account switched successfully`});
         setTimeout( function(){
            window.location.href = ROUTES.DASHBOARD
         }, 1500);
      }
   }

   const handleAllocateClick = (id) => {
      navigate(`/allocate/${id}`)

   }

   if (loading) {
      return <Loader />
   }


   const RenderActionComponent = ({id,role}) => {
   
      const userEditRoute = ROUTES.USERS.EDIT.replace(':id', id);
      return (
         <div className="flex align-items-center list-user-action">
             {isSuperAdmin && (
               <span className='pointer' title='Switch account' onClick={e => switchAccount(id)}>
                     <img alt='Switch account' src={switchUserIcon} width={32} />
               </span>
            )}{' '}
            <Link className="btn btn-sm btn-icon btn-warning" data-toggle="tooltip" data-placement="top" title="Edit" data-original-title="Edit" to={`/${userEditRoute}`} >
               <span className="btn-inner">
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
               </span>
            </Link>{' '}
            {!(selectedUsers.length > 0) && (
                <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="Delete" data-original-title="Delete" to="#" onClick={() => handleDeleteUser(id)}>
                    <span className="btn-inner">
                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                            <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                </Link>
            )}
             {' '}
              { role === USER_ROLES.RESELLER || !isSuperAdmin ? (
                 <Button className="btn btn-sm btn-icon btn-primary" title='Allocate' onClick={() => handleAllocateClick(id)}>
                 Allocate
             </Button>
              ): null}
         </div>
      );
   }

   const dropdownItemTemplate = (option) => {
      return <span>{option}</span>;
   }

   const statusFilterTemplate = (options) => {
      return <Dropdown value={options.value} options={statuses.current} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
   }

   
   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        console.log(err)
      }
   }

   function onAccountSwitchError(err) {
      setToastProps({type: 'danger', message: `Access Denied ! Only Superadmin can switch`});
   }
   

   // Map the role to the role name from USERS_ROLE
   const userTypeTemplate = (rowData) => {
      const userRole = USERS_ROLE[rowData.role]; 
      return <span>{userRole}</span>;
    };

     const handleCreateButtonClick = () => {
      navigate(`/${ROUTES.USERS.CREATE}`);
   } 

   const rowClassName = (data) => {
      return {
          'highlighted-row': selectedUsers.includes(data._id)
        };
    }

    const actionBodyTemplate = (rowData) => {
        return <RenderActionComponent id={rowData._id} role={rowData.role} username={rowData.username}/>;
    };

  return(
     <>
       <div>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-block justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">Users List</h4>
                        {/* <RenderUserAccessRolesList activeUserAccessRole={activeUserAccessRole} userAccessRoles={userAccessRoles} setActiveUserAccessRole={setActiveUserAccessRole} /> */}
                     </div>
                     {((userRole === USER_ROLES.SUB_SUPERADMIN) ? (moduleOperations?.Users?.add) : true) && (
                        <button className="btn btn-primary ml-auto" onClick={handleCreateButtonClick}>Create User</button>
                    )}
                  </Card.Header>
                  <Card.Body className="px-0">
                     
                  <div className="table-responsive border-bottom my-3">
                 
                  <Button 
                     label="Delete Selected" 
                     onClick={handleMultiDelete} 
                     className="p-button-danger p-button-sm" 
                     style={{ marginBottom: '10px', marginLeft: '10px' }} 
                     disabled={selectedUsers.length === 0} 
                  />

                  <Button 
                     label="Export Data" 
                     onClick={handleExport} 
                     className="p-button-active p-button-sm" 
                     style={{ marginBottom: '10px', marginLeft: '10px' }} 
                     disabled={buttonDisable} 
                  >
                     &nbsp;
                     {buttonDisable && (
                        <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                     )}
                  </Button>
                  <DataTable value={userlist} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8} rowClassName = {rowClassName}>
                     <Column 
                        header={<Checkbox 
                              checked={selectedUsers.length === userlist.length && userlist.length > 0 } 
                              onChange={handleSelectAll} 
                        />} 
                        headerStyle={{ width: '3rem' }} 
                        body={(rowData) => (
                              <Checkbox 
                                 checked={selectedUsers.includes(rowData._id)} 
                                 onChange={() => handleCheckboxToggle(rowData._id)} 
                              />
                        )} 
                     />
                     <Column field="username" filterField="username" header="Username" filter filterPlaceholder="Search by username" />
                     <Column field="name" filterField="name" header="Name" filter filterPlaceholder="Search by name" />
                     <Column field="email" filterField="email" header="Email" filter filterPlaceholder="Search by email" />
                     <Column field="USERS_ROLE.role" header="Type" body={userTypeTemplate} />
                     <Column field="company_name" filterField="company_name" header="Company Name" filter filterPlaceholder="Search by company name" />
                     <Column field="status" header="Status" filterElement={statusFilterTemplate} filter />
                     <Column field="createdAt" header="Joining Date" />
                     {((userRole === USER_ROLES.SUB_SUPERADMIN) ? (moduleOperations?.Users?.edit || moduleOperations?.Users?.delete) : true) && (
                      <Column header="Action" body={actionBodyTemplate} />
                    )}
                    
                  </DataTable>
        </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </div>

      <RenderDeleteModal 
      show={enableDeleteModal} 
                setShow={setEnableDeleteModal} 
                setConfirmDelete={setConfirmDelete} 
                isMultiDelete={isMultiDelete} 
                clearSelectedData={() => setSelectedUsers([])}
      />

      <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

     </>
  )

}

export default UserList;