import React, { useState, useEffect } from 'react'
import {Row,Col,Form,Button} from 'react-bootstrap'
import Card from '../../../components/Card'
import ToastNotification from '../../../components/Toast';
import { API_USERS_PROFILE } from '../../../service/url';
import { postApiCall, getApiCall } from '../../../service/httpCall';
import { useNavigate, useParams } from 'react-router-dom';
import { USER_STATUS } from '../../../utilities/constant';
import { ROUTES } from '../../../utilities/constant';
import { useAuth, errorCheck } from '../auth/auth-utils';

const VALIDATION_MIN_PASSWORD_LENGTH = 6;

const CallCentreUserEdit = () => {

   const { id, uid } = useParams();
   const navigate = useNavigate();
   const { checkAuth } = useAuth();
   const [username, setUsername] = useState('');
   const [password, setPassword] = useState('');
   const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [status, setStatus] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);

   function getProfileApiUrl(){
      return API_USERS_PROFILE.replace(':id', uid);
   }

   useEffect( () => {

      const profileApiUrl = getProfileApiUrl();
      getApiCall(profileApiUrl, {})
      .then(onProfileGetSuccess).catch(onError);

   }, [id]);

   useEffect(() => {
      checkAuth(setToastProps, setShowToast)
    }, []);

   useEffect( () => {

      if(toastProps){
         setShowToast(true);
      }

   }, [toastProps]);

   function onProfileGetSuccess(resp){
         //Set user details
         setUsername(resp.username);
         setStatus(resp.status);
         setName(resp.name);
         setEmail(resp.email);
   }


   function validateForm(){

      let valid = true;

      if(!username.trim()){
         valid = false;
         setToastProps({message: 'Username required', type: 'danger'});
      }
      else if(!status || isNaN(status)){
         valid = false;
         setToastProps({message: 'Status required', type: 'danger'});
      }
      else if(password.trim() && confirmPassword.trim()){
      
         if(password.trim().length < VALIDATION_MIN_PASSWORD_LENGTH || confirmPassword.trim().length < VALIDATION_MIN_PASSWORD_LENGTH){
            valid = false;
            setToastProps({message: `Password and confirm password must be of minimum ${VALIDATION_MIN_PASSWORD_LENGTH} characters`, type: 'danger'});
         }
         else if(password.trim() != confirmPassword.trim()){
            valid = false;
            setToastProps({message: 'Password and confirm password not matching', type: 'danger'});
         }
      
      }

      return valid;

   }

   async function submitForm(){

      setButtonDisable(true);

      if(!validateForm()){
         setButtonDisable(false);
         return;
      }

      const params = { username, name, email, status }
      if(password){
         params['password'] = password;
      }

      const profileApiUrl = getProfileApiUrl();
      postApiCall(profileApiUrl, params)
      .then(onUserUpdateSuccess)
      .catch(onError);

   }

   function onUserUpdateSuccess(resp){
      setToastProps({message: 'User updated successfully', type: 'success'});
      setTimeout( () => {
         const callCentreUsersListPath = ROUTES.CALL_CENTRES.USERS.LIST.replace(':id', id);
         navigate(`/${callCentreUsersListPath}`);
      }, 1500);
   }

   
   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        setButtonDisable(false);
        setToastProps({message: JSON.stringify(err), type: 'danger'});
      }
   }

   function RenderUserStatusOptions(){

      let arrStatusOptions = [<option value=''>Select status</option>]

      USER_STATUS.map( val => {
         arrStatusOptions.push(<option value={val.value}>{val.label}</option>);
      } )

      return arrStatusOptions;

   }


   return(
      <>
        <div>
            <Row>
               <Col xl="12" lg="12">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">User Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div className="new-user-info">
                           <form>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="username">User Name:</Form.Label>
                                    <Form.Control type="text"  id="username" placeholder="User Name" value={username} onChange={e => setUsername(e.target.value)} />
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="name">Full Name:</Form.Label>
                                    <Form.Control type="text"  id="name" maxLength={100} value={name} onChange={e => setName(e.target.value)} required />
                                 </Form.Group>
                              </div>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="email">Email:</Form.Label>
                                    <Form.Control type="email"  id="email" maxLength={100} value={email} onChange={e => setEmail(e.target.value)} required />
                                 </Form.Group>
                              </div>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="pass">Password:</Form.Label>
                                    <Form.Control type="password"  id="pass" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}  />
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="rpass">Confirm Password:</Form.Label>
                                    <Form.Control type="password"  id="rpass" placeholder="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                 </Form.Group>
                              </div>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="status">Status:</Form.Label>
                                    <select value={status} onChange={e => setStatus(e.target.value)} className="form-select" id="status">
                                        <RenderUserStatusOptions/>
                                    </select>
                                 </Form.Group>
                              </div>
                              <hr/>
                              <Button onClick={async() => {submitForm()}} type="button" variant="btn btn-primary" disabled={buttonDisable}>
                                 Update &nbsp;
                                 {
                                    buttonDisable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>
                                 }
                              </Button>
                           </form>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>

         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

      </>
  )

}

export default CallCentreUserEdit;