import { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function AddNodeModal(props){

    const bodyRef = useRef(null);
    const handleClose = () => props.setShow(false);

    function onNodeSave(){

        const formValues = bodyRef.current.getFormValues();

        if(formValues.hasOwnProperty('type') && formValues.hasOwnProperty('data')){
            const formValid = Boolean(formValues.type == 'play' && formValues.data.targetVal) || Boolean(formValues.type == 'input' && formValues.data.length > 0);
            if(formValid) props.updateActionBodyContent(formValues);
        }

    }

    return (
        <Modal className='modal-lg' show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Node</Modal.Title>
            </Modal.Header>
            <Modal.Body><props.body ref={bodyRef} nodeList={props.nodeList} selectedKey={props.selectedKey} actionBodyContent={props.actionBodyContent} /></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" type="submit" onClick={onNodeSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );

}