import React, { useState, useEffect } from 'react'
import Card from '../../../components/Card'
import { Row, Col, Button } from 'react-bootstrap'

import ToastNotification from '../../../components/Toast';
import { postApiCall, getApiCall } from '../../../service/httpCall';
import { API_MODULE_EDIT } from '../../../service/url';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES} from '../../../utilities/constant';
import RenderModuleForm from './_form';
import { validatePhone } from '../../../utilities/helpers';
import { useAuth, errorCheck } from '../auth/auth-utils';

function getApiUrl(url, id){
    return url.replace(':id', id);
}

export default function ModuleEdit() {
   const { checkAuth } = useAuth();
   const { id } = useParams();
   const navigate = useNavigate();

   const [moduleName, setModuleName] = useState('');
   const [status, setStatus] = useState('');

   //const [ipIdentifyBy, setIpIdentifyBy] = useState('');

   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);

   useEffect(() => {

      if (toastProps) {
         setShowToast(true);
      }

   }, [toastProps]);

   useEffect(() => {
      checkAuth(setToastProps, setShowToast)
    }, []);

    useEffect(() => {

        const editApiUrl = getApiUrl(API_MODULE_EDIT, id);
        getApiCall(editApiUrl, {})
            .then(onGetSuccess).catch(onError);

    }, [id]);


    function onGetSuccess(resp){
        setModuleName(resp.module_name);
        setStatus(resp.status);

    }


   function validateForm() {

      let valid = true;

      if (!moduleName.trim()) {
         valid = false;
         setToastProps({ message: 'Module Name required', type: 'danger' });
      }
      else if(isNaN(status)){
         valid = false;
         setToastProps({ message: 'Status required', type: 'danger' });
      }
      

      return valid;

   }

   async function submitForm(e) {

      e.preventDefault();

      setButtonDisable(true);

      if (!validateForm()) {
         setButtonDisable(false);
         return;
      }

      let params = {module_name:moduleName,status:status}
      const editApiUrl = getApiUrl(API_MODULE_EDIT, id);
      postApiCall(editApiUrl, params)
         .then(onModuleUpdateSuccess)
         .catch(onModuleUpdateError);

   }

   function onModuleUpdateSuccess(resp) {
      setToastProps({ message: 'Module updated successfully', type: 'success' });
      setTimeout(() => {
         navigate(`/${ROUTES.MODULE.LIST}`);
      }, 1500);
   }

   function onModuleUpdateError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        setButtonDisable(false);
        setToastProps({message: JSON.stringify(err), type: 'danger'});
      }
   }

   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
       errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
      setButtonDisable(false);
      setToastProps({message: JSON.stringify(err), type: 'danger'});
    }
   
 }

   return (
      <>

         <div>
            <Row>
               <Col xl="12" lg="12">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Update Module Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>

                        <div className="new-module-info"></div>

                        <form onSubmit={async (e) => { submitForm(e) }}>

                           <RenderModuleForm
                              isEditMode={true}
                              moduleName={moduleName} setModuleName={setModuleName}
                              status={status} setStatus={setStatus}
                           />

                           <Button type={'submit'} variant="btn btn-primary" disabled={buttonDisable}>
                              Update Module &nbsp;
                              {
                                 buttonDisable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>
                              }
                           </Button>

                        </form>

                     </Card.Body>
                  </Card>
               </Col>
            </Row>

         </div>


         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

      </>
   )

}