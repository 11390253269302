import React, { useState, useEffect } from 'react'
import {Row,Col,Form,Button} from 'react-bootstrap'
import Card from '../../../components/Card'
import ToastNotification from '../../../components/Toast';
import { API_COMPANY_CREATE } from '../../../service/url';
import { postApiCall } from '../../../service/httpCall';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utilities/constant';

const CompanyCreate = () => {

   const navigate = useNavigate();

   const [name, setName] = useState('');
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);


   useEffect( () => {

      if(toastProps){
         setShowToast(true);
      }

   }, [toastProps]);

   function validateForm(){

      let valid = true;

      if(!name.trim()){
         valid = false;
         setToastProps({message: 'Name required', type: 'danger'});
      }

      return valid;

   }

   async function submitForm(){

      setButtonDisable(true);

      if(!validateForm()){
         setButtonDisable(false);
         return;
      }

      const params = { name }

      postApiCall(API_COMPANY_CREATE, params)
      .then(onCreateCreateSuccess)
      .catch(onError);

   }

   function onCreateCreateSuccess(resp){
      setToastProps({message: 'Company created successfully', type: 'success'});
      setTimeout( () => {
         navigate(`/${ROUTES.COMPANIES.LIST}`);
      }, 1500);
   }

   function onError(err){
      setButtonDisable(false);
      console.log(err);
      setToastProps({message: JSON.stringify(err), type: 'danger'});
   }

   return(
      <>
        <div>
            <Row>
               <Col xl="12" lg="12">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">New Company Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div className="new-user-info">
                           <form>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="name">Name:</Form.Label>
                                    <Form.Control type="text" id="name" placeholder="Company Name" value={name} onChange={e => setName(e.target.value)} />
                                 </Form.Group>
                              </div>
                              <hr/>
                              <Button onClick={submitForm} type="button" variant="btn btn-primary" disabled={buttonDisable}>
                                 Create New Company &nbsp;
                                 {
                                    buttonDisable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>
                                 }
                              </Button>
                           </form>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>

         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

      </>
  )

}

export default CompanyCreate;