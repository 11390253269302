import { apiWithGetHeader } from "./api";
import { API_STATUS } from "./constant";
import { BACKEND_API_BASE_URL } from "../utilities/constant";
import { getUserAccessToken } from "../utilities/setting";

export const getApiCall = async (url, payload) => {

    const accessToken = await getUserAccessToken();

    const response = await apiWithGetHeader(BACKEND_API_BASE_URL, payload, accessToken)
    .get(url)
    .catch(function (error) {
        if(error.response == undefined){
            var errorMsg = 'A network error occurred. This could be a CORS issue or a dropped internet connection.';
        }else{
            const dataMsg = error.response.data.message ? String(error.response.data.message) : '';
            const explanationMsg = error.response.data.error.explanation ? String(error.response.data.error.explanation) : '';
            var errorMsg = dataMsg
        }
        return Promise.reject(errorMsg);
    });

    if (response.status == API_STATUS.SUCCESS || response.status == API_STATUS.CREATED || response.status == API_STATUS.ACCEPED) {
        return response.data.data;
    } else {
        return Promise.reject(response);
    }

};