import { useState, forwardRef, useImperativeHandle } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

const dtmfArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '*', '#'];
const dtmfInput = dtmfArr.map( val => {
    return { dtmf: val, target: '', label: '', sms_api_url: '' }
} );

const RenderInputActionModalBody = forwardRef((props, _ref) => {

    const [dtmfTarget, setDtmfTarget] = useState(dtmfInput);

    function updateTarget(value, idx){
        const updatedDtmfTarget = dtmfTarget.map(function(val, i){
            if(i == idx) val.target = value;
            return val;
        });
        setDtmfTarget(updatedDtmfTarget);
    }

    function updateLabel(value, idx){
        const updatedDtmfTarget = dtmfTarget.map(function(val, i){
            if(i == idx) val.label = value;
            return val;
        });
        setDtmfTarget(updatedDtmfTarget);
    }


    function updateSMSApiUrl(value, idx){
        const updatedDtmfTarget = dtmfTarget.map(function(val, i){
            if(i == idx) val.sms_api_url = value;
            return val;
        });
        setDtmfTarget(updatedDtmfTarget);
    }

    useImperativeHandle(_ref, () => ({
        getFormValues: () => {
            const updatedDtmfTarget = dtmfTarget.filter(v => v.target != '')
            return {type: 'input', data: updatedDtmfTarget}
        }
    }));

    return (<>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>DTMF</th>
                            <th>Target</th>
                            <th>Label</th>
                            <th>SMS API Url</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dtmfTarget.map( function(val, idx){
                                return (<tr key={idx}>
                                    <td>{val.dtmf}</td>
                                    <td>
                                        <Form.Select value={val.target} aria-label="Select target" onChange={(e) => updateTarget(e.target.value, idx)}>
                                            <option value=''>Select target</option>
                                            <option value="new_node">New Node</option>
                                            {
                                                props.nodeList.current.filter(val => {
                                                    return val.key != props.selectedKey  
                                                })
                                                .map(val => <option key={val.key} value={val.key}>{val.label}</option>)
                                            }
                                        </Form.Select>
                                    </td>
                                    <td>
                                        <Form.Control type="text" value={val.label} onChange={e => updateLabel(e.target.value, idx)} disabled={val.target != 'new_node'} />
                                    </td>
                                    <td>
                                        <Form.Control type="text" value={val.sms_api_url} onChange={e => updateSMSApiUrl(e.target.value, idx)} disabled={val.target != 'new_node'} />
                                    </td>
                                </tr>)
                            } )
                        }
                    </tbody>
                </Table>
    </>);

});


export default RenderInputActionModalBody;