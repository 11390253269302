import { apiWithHeader, apiWithoutHeader } from "./api";
import { API_STATUS } from "./constant";
import { BACKEND_API_BASE_URL } from "../utilities/constant";
import { getUserAccessToken } from "../utilities/setting";

export const deleteApiCall = async(url) => {
  
    const response = await apiWithoutHeader(BACKEND_API_BASE_URL)
    .delete(url)
    .catch(async function (error) {
      return error.response;
    });

    if (response.status == API_STATUS.SUCCESS || response.status == API_STATUS.CREATED || response.status == API_STATUS.ACCEPED) {
        return response.data.data;
    } else {
        return Promise.reject(response);
    }

};

export const deleteApiCallWithHeader = async(url) => {

    const accessToken = await getUserAccessToken();
  
    const response = await apiWithHeader(BACKEND_API_BASE_URL, 'application/json', accessToken)
    .delete(url)
    .catch(async function (error) {
        if(error.response == undefined){
            var errorMsg = 'A network error occurred. This could be a CORS issue or a dropped internet connection.';
        }else{
            const dataMsg = error.response.data.message ? String(error.response.data.message) : '';
            //const explanationMsg = error.response.data.error.explanation ? String(error.response.data.error.explanation) : '';
            var errorMsg = dataMsg
        }
        return errorMsg;
    });

    if (response.status == API_STATUS.SUCCESS || response.status == API_STATUS.ACCEPED) {
        return response.data.data;
    } else {
        return Promise.reject(response);
    }

};
