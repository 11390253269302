import React, { useState, useEffect } from 'react'
import Card from '../../../components/Card'
import { Row, Col, Button } from 'react-bootstrap'

import ToastNotification from '../../../components/Toast';
import { postApiCall } from '../../../service/httpCall';
import { postApiMediaCall } from '../../../service/postApiCall';
import { API_NUMBERS_UPLOAD, API_NUMBERS_CREATE } from '../../../service/url';
import { useNavigate } from 'react-router-dom';
import { NUMBERS_FORMAT_INDIVIDUAL, NUMBERS_FORMAT_FILE, ROUTES } from '../../../utilities/constant';
import RenderNumberForm from './_form';
import { useAuth, errorCheck } from '../auth/auth-utils';

export default function NumbersCreate() {

    const navigate = useNavigate();
    const { checkAuth } = useAuth();
    const [formatType, setFormatType] = useState('');
    const [countryCodeId, setCountryCodeId] = useState('');
    const [companies, setCompanies] = useState('');
    const [numberType,setNumberType] =useState('');
    const [category,setCategory] = useState('');
    const [currency,setCurrency] = useState('');
    const [did,setDid] = useState('');
    const [cost,setCost] =useState('');
    const [operator,setOperator] = useState('');
    const [stateCode,setStateCode] =useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState(null);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [uploadFile, setUploadFile] = useState(undefined);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [buttonFileDisable, setButtonFileDisable] = useState(false);
    const [actualNumber, setActualNumber] = useState('');
    useEffect(() => {
        if (toastProps) {
            setShowToast(true);
        }

    }, [toastProps]);
    
    useEffect(() => {
        checkAuth(setToastProps, setShowToast);
      }, []);


    function validateForm() {

        let valid = true;
        if (!formatType) {
            valid = false;
            setToastProps({ message: 'Format Type required', type: 'danger' });
        }
       
        else if (formatType === NUMBERS_FORMAT_FILE) {
            if (!isFileUpload && !uploadFile) {
                setToastProps({ message: 'Numbers File is Required', type: 'danger' });
                valid = false;
            }
        }
        
        else if (!numberType) {
            valid = false;
            setToastProps({ message: 'Number Type is required', type: 'danger' });
        } else if (numberType === 'DID' && !category) {
            valid = false;
            setToastProps({ message: 'Category is required for DID', type: 'danger' });
        } else if (!currency) {
            valid = false;
            setToastProps({ message: 'Currency is required', type: 'danger' });
        }
        else if (formatType === NUMBERS_FORMAT_INDIVIDUAL) {
            if (!did) {
                valid = false;
                setToastProps({ message: 'DID is required', type: 'danger' });
            } else if (!cost) {
                valid = false;
                setToastProps({ message: 'Cost is required', type: 'danger' });
            } else if (!operator) {
                valid = false;
                setToastProps({ message: 'Operator is required', type: 'danger' });
            } else if (numberType === 'DID' && !stateCode) {
                valid = false;
                setToastProps({ message: 'State Code is required for DID', type: 'danger' });
            }
        }
        

        return valid;

    }
    

    async function submitFileForm() {

       const formData = new FormData();
        formData.append('pool', 'pool');
        formData.append('phone_numbers', uploadFile);
        formData.append("numberType", numberType); 
        formData.append("category", category);     
        formData.append("currency", currency); 
        formData.append('phone_number', NUMBERS_FORMAT_FILE)
 
        postApiMediaCall(API_NUMBERS_UPLOAD, formData)
            .then(resp => {
                onNumbersUploadSuccess(resp);
            })
            .catch(err => {
                onNumbersUploadError(err);
            })

    }


    function onNumbersUploadError(err) {
        if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
            errorCheck(err, navigate, setToastProps, setShowToast)
         } else {
           setButtonDisable(false);
           setToastProps({message: JSON.stringify(err), type: 'danger'});
         }
    }

    function onNumberCreateSuccess(resp) {
        setToastProps({ message: 'Numbers created successfully', type: 'success' });
        setTimeout(() => {
            navigate(`/${ROUTES.NUMBERS.LIST}`);
        }, 1500);
    }

    function onNumbersUploadSuccess(resp) {
        setToastProps({ message: 'Numbers uploaded successfully', type: 'success' });
        setTimeout(() => {
            navigate(`/${ROUTES.NUMBERS.LIST}`);
        }, 1500);
    }

    function onNumberCreateError(err) {
        if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
            errorCheck(err, navigate, setToastProps, setShowToast)
         } else {
            console.error('Number creation error:', err); 
           setButtonDisable(false);
           setToastProps({message: JSON.stringify(err), type: 'danger'});
         }
    }

    async function submitForm(e) {

        e.preventDefault();
         
        setButtonDisable(true);
        if (!validateForm()) {
            setButtonDisable(false);
            return;
        }

        if (formatType === NUMBERS_FORMAT_FILE) {
            submitFileForm()
        }
        else if (formatType === NUMBERS_FORMAT_INDIVIDUAL) {

            let params = {
                countryCode: countryCodeId,
                numberType: numberType,
                currency: currency,
                DID: did,
                cost: cost,
                operator: operator,
                
            }
            if (numberType === 'DID') {
                params.category = category;
                params.stateCode = stateCode; 
                } 
            

            postApiCall(API_NUMBERS_CREATE, params)
                .then(onNumberCreateSuccess)
                .catch(onNumberCreateError);
        }

    }

    return (
        <>

            <div>
                <Row>
                    <Col xl="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">New Numbers Information</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>

                                <div className="new-trunk-info"></div>

                                <form onSubmit={async (e) => { submitForm(e) }}>

                                    <RenderNumberForm
                                        countryCodeId={countryCodeId} setCountryCodeId={setCountryCodeId}
                                        formatType={formatType} setFormatType={setFormatType}
                                        uploadFile={uploadFile} setUploadFile={setUploadFile}
                                        isFileUpload={isFileUpload} setIsFileUpload={setIsFileUpload}
                                        numberType={numberType} setNumberType={setNumberType}
                                        category={category} setCategory={setCategory}
                                        currency={currency} setCurrency={setCurrency}
                                        actualNumber={actualNumber} setActualNumber={setActualNumber}
                                        cost={cost} setCost={setCost}
                                        operator={operator} setOperator={setOperator}
                                        stateCode={stateCode} setStateCode={setStateCode}
                                        did={did} setDid={setDid}
                                        buttonFileDisable={buttonFileDisable} setButtonFileDisable={setButtonFileDisable}
                                        buttonDisable={buttonDisable} setButtonDisable={setButtonDisable}
                                        toastProps={toastProps} setToastProps={setToastProps}
                                    />

                                    <Button type={'submit'} variant="btn btn-primary" disabled={buttonDisable}>
                                        Add Numbers &nbsp;
                                        {
                                            buttonDisable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>
                                        }
                                    </Button>

                                </form>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </div>


            <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

        </>
    )

}