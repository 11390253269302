import { apiWithHeader } from "./api";
import { API_STATUS } from "./constant";
import { BACKEND_API_BASE_URL } from "../utilities/constant";
import { getUserAccessToken } from "../utilities/setting";

export const postApiCall = async (url, params) => {

    const token = await getUserAccessToken();
  
    const response = await apiWithHeader(BACKEND_API_BASE_URL, 'application/json', token)
    .post(url, params)
    .catch(function (error) {
      if(error.response == undefined){
        var errorMsg = 'A network error occurred. This could be a CORS issue or a dropped internet connection.';
      }else{
        const dataMsg = error.response.data.message ? String(error.response.data.message) : '';
        const explanationMsg = error.response.data.error.explanation ? String(error.response.data.error.explanation) : '';
        var errorMsg = `${dataMsg} ${explanationMsg}`
      }
      return Promise.reject(errorMsg);
    });
  
    if (response.status == API_STATUS.SUCCESS || response.status == API_STATUS.ACCEPED || response.status == API_STATUS.CREATED) {
        return response.data.data;
    } else {
        return Promise.reject(response.data);
    }

};

// export const postApiMediaCall = async (url, formData) => {
  
//     return apiWithHeader(BACKEND_API_BASE_URL, 'multipart/form-data')
//     .post(url, formData)
//     .catch(function (error) {
//       return error.response;
//     });

// };

export const postApiMediaCall = async (url, formData) => {
  const token = await getUserAccessToken();
  
  const response = await apiWithHeader(BACKEND_API_BASE_URL, 'multipart/form-data', token)
  .post(url, formData)
  .catch(function (error) {
      if(error.response == undefined){
          var errorMsg = 'A network error occurred. This could be a CORS issue or a dropped internet connection.';
      }else{
          const dataMsg = error.response.data.message ? String(error.response.data.message) : '';
          const explanationMsg = error.response.data.error.explanation ? String(error.response.data.error.explanation) : '';
          var errorMsg = `${dataMsg} ${explanationMsg}`
      }
      return Promise.reject(errorMsg);
  });

  if (response.status === API_STATUS.SUCCESS || response.status === API_STATUS.ACCEPTED || response.status === API_STATUS.CREATED) {
      return response.data.data;
  } else {
      return Promise.reject(response.data);
  }
};
