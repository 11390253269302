export const API_STATUS = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPED: 202,
    ALREADY_REPORTED: 208,
    UNAUTHORIZED: 401,
    INVAILDCREDENTIALS: 1001,
    NOT_FOUND: 404,
    FORBIDDEN: 422,
    ACCESS_DENIED: 403
  };