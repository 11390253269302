import { getApiCall} from '../service/httpCall';
import { API_LIST_EXPORT } from '../service/url';



function getApiUrl(url, model) {
  return url.replace(":model", model);
}

const exportData = async (model, setToastProps, setShowToast) => {
    try {
      const exportApiUrl = getApiUrl(API_LIST_EXPORT, model);
      const response = await getApiCall(exportApiUrl, {});
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${model}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      setToastProps({ message: "Error in exporting data", type: "danger" });
      setShowToast(true);
      console.error(`Error exporting ${model} data:`, err);
    }
  };

export default exportData;
