import AsyncStorage from '@react-native-async-storage/async-storage';
import { jwtDecode } from 'jwt-decode';
import { ROUTES } from "../../../utilities/constant";
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation

export const useAuth = () => {
    const navigate = useNavigate();

    const checkAuth = async (setToastProps, setShowToast) => {
        try {
            const token = await AsyncStorage.getItem('access_token');
            if (!token) {
                throw Error;
            }
            const { exp } = jwtDecode(token);

            if (exp * 1000 < Date.now()) {
                await AsyncStorage.clear();
                throw Error;
            }
        } catch (error) {
            console.log('Error decoding token:', error);
            errorCheck(error, navigate, setToastProps, setShowToast);
        }
    };

    return { checkAuth };
};

export const errorCheck = (err, navigate, setToastProps, setShowToast) => {

    if (typeof navigate === 'function') {
        setToastProps({ message: "Session Expired", type: "danger" });

        setShowToast(true);

        AsyncStorage.clear()
            .then(() => {
                console.log('AsyncStorage cleared');
                setTimeout(() => {
                    navigate(`/${ROUTES.LOGIN}`);
                    setShowToast(false); 
                }, 1000);
            })
            .catch((error) => {
                console.error('Error clearing AsyncStorage:', error);
            });
    }
};
