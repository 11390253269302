import React, { useState, useEffect } from "react";
import { getApiCall, postApiCall } from "../../../service/httpCall";
import { Row, Col, Card } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ToastNotification from "../../../components/Toast";
import {
    API_NUMBERS_LIST,
    API_NUMBER_STATUS_CHANGE,
} from "../../../service/url";
import Loader  from '../../../components/partials/components/loader';
import { useAuth, errorCheck } from '../auth/auth-utils';
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';
import "../../../assets/custom/css/custom.css";


export default function StatusChangeActionList() {
  const [toastProps, setToastProps] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [numbersList, setNumberList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState('');

  const { checkAuth } = useAuth();
  const navigate = useNavigate();


  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    setLoading(true);
    getApiCall(API_NUMBERS_LIST)
      .then(onNumberFileListSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);


  function onNumberFileListSuccess(resp) {
    const filteredList = resp.filter(val => val.updated_status !== null);
    
    const updatedList = filteredList.map((val, idx) => {
      val["key"] = idx + 1;
      val["createdAt"] = new Date(val["createdAt"]).toGMTString();
      return val;
    });
    
    setNumberList(updatedList);
}

  const RenderActionComponent = ({id}) => {
    return (
        <div className="flex items-center space-x-2 list-user-action">
            <Button 
                onClick={() => handleApproveClick(id)} 
                className="p-button-active p-button-sm text-xs px-2 py-1" 
                style={{ marginLeft: '10px' }} 
            > Approve </Button>
            <Button 
                onClick={() => handleRejectClick(id)} 
                className="p-button-danger p-button-sm text-xs px-2 py-1" 
                style={{ marginLeft: '10px' }} 
            > Reject </Button>
        </div>
    );
  };

  function handleApproveClick(id) {
    checkAuth(setToastProps, setShowToast)
    const params = {
        updateId: id,
        status: updatedStatus,
        updated_status: null,
        action: 'Approve'
    }
    postApiCall(API_NUMBER_STATUS_CHANGE, params)
        .then(() => onStatusChangeSuccess(id))
        .catch(onError);
  }

  function handleRejectClick(id) {
    const params = {
        updateId: id,
        status: 10,
        updated_status: null,
        action: 'Reject'
    }
    postApiCall(API_NUMBER_STATUS_CHANGE, params)
        .then(() => onStatusChangeSuccess(id))
        .catch(onError);
  }


  function onStatusChangeSuccess(deletedId) {
    setToastProps({
      type: "success",
      message: "Status Changed Successfully.",
    });
    let updatedList;
   
    updatedList = numbersList
     .filter((val) => val._id != deletedId)
     .map((val, key) => {
        val["key"] = key + 1;
        return val;
      });
    setNumberList(updatedList);
  }


  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     console.log(err)
   }
 }


  const actionBodyTemplate = (rowData) => {
    setUpdatedStatus(rowData.updated_status);
    return <RenderActionComponent id={rowData._id} />;
  };


  if (loading) {
    return <Loader />
  }

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <div className="header-title">
                <h4 className="card-title">Status Change Action List</h4>
              </div>
            </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive border-bottom my-3">
                  <DataTable value={numbersList} tableStyle={{ minWidth: '50rem' }} paginator rows={8} >
                      <Column field="actual_number" filterField="actual_number" header="DID" filter filterPlaceholder="Search by Number" ></Column>
                      <Column field="number_type" filterField="number_type" header="Number Type" filter filterPlaceholder="Search by Number Type"></Column>
                      <Column field="status" header="Status" filter></Column>
                      <Column field="country_code" filterField="country_code" header="Country Code" ></Column>
                      <Column field="cost" filterField="cost" header="Cost" filter filterPlaceholder="Search by Cost" ></Column>
                      <Column field="operator" filterField="operator" header="Operator" filter filterPlaceholder="Search by Operator" ></Column>
                      <Column header="Action" body={actionBodyTemplate} />
                    </DataTable>
                </div>
              </Card.Body>

            </Card>
          </Col>
        </Row>
      </div>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
};

