import React, { useState, useEffect} from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import { getApiCall} from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import { API_JOURNEY_OPERATION_LIST} from '../../../service/url';
import Loader  from '../../../components/partials/components/loader';
import exportData from '../../../utilities/export';
//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';
import {errorCheck } from '../auth/auth-utils';
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';



export default function JourneyOperationList() {
   const navigate = useNavigate();
   const [operationList, setOperationList] = useState([]);
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [filters, setFilters] = useState({
      module_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      action: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      'createdBy.username':{ operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
  });
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [loading, setLoading] = useState(false);
   const [buttonDisable, setButtonDisable] = useState(false);

   const handleExport = async () => {
      setButtonDisable(true); 
      await exportData('UserJourney', setToastProps, setShowToast);
      setButtonDisable(false); 
   };

   useEffect(() => {

      if (toastProps) {
         setShowToast(true);
      }

   }, [toastProps]);

   useEffect(() => {
      setLoading(true);
      getApiCall(API_JOURNEY_OPERATION_LIST)
         .then(onOperationListSuccess)
         .catch(onError)
         .finally(() => setLoading(false));

   }, []);


   function onOperationListSuccess(resp) {
      const updatedList = resp.map((val, idx) => {
         val['key'] = idx + 1;
         //val['actions'] = RenderActionComponent(val._id);
         val['createdAt'] = new Date(val['createdAt']).toGMTString();
         return val;
      });
      setOperationList(updatedList);
   }


   const dropdownItemTemplate = (option) => {
      return <span>{option}</span>;
   }

   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        console.log(err)
      }
   }

   if (loading) {
      return <Loader />
   }

   return (
      <>
         <div>
            <Row>
               <Col sm="12">
                  <Card>
                     <Card.Header className="d-block justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Operation List</h4>
                        </div>
                     </Card.Header>
                     <Card.Body className="px-0">
                        <Button 
                           label="Export Data" 
                           onClick={handleExport} 
                           className="p-button-active p-button-sm" 
                           style={{ marginBottom: '10px', marginLeft: '10px' }} 
                           disabled={buttonDisable} 
                        >
                           &nbsp;
                           {buttonDisable && (
                              <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                           )}
                        </Button>
                        <div className="table-responsive border-bottom my-3">
                           <DataTable value={operationList} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8} >
                              <Column field="key" header="#"></Column>
                              <Column field="module_name" filterField="module_name" header="Module Name" filter filterPlaceholder="Search by Module name" ></Column>
                              <Column field="action" filterField="action" header="Action" filter filterPlaceholder="Search by action" ></Column>
                              <Column field="createdBy.username" filterField="createdBy.username" header="Created By" filter filterPlaceholder="Search by Created By"></Column>
                              <Column field="createdAt" header="Created Date"></Column>
                           </DataTable>
                        </div>

                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>

         <RenderDeleteModal show={enableDeleteModal} setShow={setEnableDeleteModal} setConfirmDelete={setConfirmDelete} />

         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

      </>
   )

}