import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import downloadTemplate from '../../../utilities/downloadCsv'
import { Button } from 'primereact/button';
import {
  API_COUNTRY_CODES_LIST,
  API_STATES_LIST,
  API_OPERATORS_LIST
} from "../../../service/url";
import { getApiCall, postApiCall } from "../../../service/httpCall";
import {
  NUMBERS_FORMAT_TYPES,
  NUMBERS_FORMAT_FILE,
  NUMBERS_FORMAT_INDIVIDUAL,
  NUMBERS_TYPES,
  UPLOAD_NUMBER_DID,
  UPLOAD_NUMBER_OTHERS
 
} from "../../../utilities/constant";

function changeUploadFile(props, e) {
 
  const file = e.target.files[0];
  if (file && file.type === "text/csv") { 
    props.setUploadFile(file);
    props.setIsFileUpload(true);
  } else {
    props.setToastProps({
      message: "Please upload a CSV file.",
      type: "danger",
    });
    props.setUploadFile(null);
    props.setIsFileUpload(false);
  }
}

const getColumnsAndFilename = (numberType) => {
  let columns;
  let filename;

  if (numberType === 'DID') {
      columns = UPLOAD_NUMBER_DID;
      filename = `${numberType}-Upload.csv`;
  } else if (numberType === 'VMN') {
      columns = UPLOAD_NUMBER_OTHERS;
      filename = `${numberType}-Upload.csv`;
  } else {
      columns = UPLOAD_NUMBER_OTHERS;
      filename = `${numberType}-Upload.csv`;
  }

  return { columns, filename };
};





function RenderFormatTypeFields(props) {
  props = props["props"];
  if (props.formatType === NUMBERS_FORMAT_INDIVIDUAL) {
    return (
      <>
          <Form.Group className="col-md-6 form-group">
          <Form.Control
            type="text"
            placeholder="DID"
            id="did"
            value={props.did}
            onChange={(e) => props.setDid(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="col-md-6 form-group">
          <Form.Control
            type="number"
            placeholder="Cost"
            id="cost"
            value={props.cost}
            onChange={(e) => props.setCost(e.target.value)}
            required
          />
          </Form.Group>

        
      </>
    );
  } else if (props.formatType === NUMBERS_FORMAT_FILE) {
    return (
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          <Form.Control
            type="file"
            placeholder="Bulk Upload Numbers"
            id="uploadNumbers"
            onChange={(e) => changeUploadFile(props, e)}
            accept=".csv"
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
        {props.numberType && (
          <Button variant="btn btn-primary btn-sm" onClick={(event) => {
            const { columns, filename } = getColumnsAndFilename(props.numberType);
            downloadTemplate(event, columns, filename);
          }}>  Download Template</Button>
        )}
        </Form.Group>
        
      </div>
    );
  }
  return <></>;
}

export default function RenderNumberForm(props) {
  const [countryCodesList, setCountryCodesList] = useState([]);
  const [stateCodeList, setStateCode] = useState([]);
  const [operatorsList, setOperatorsList] = useState([]);


  useEffect(() => {

    getApiCall(API_COUNTRY_CODES_LIST, {})
      .then(onCountryCodesListSuccess)
      .catch(onError);

    getApiCall(API_OPERATORS_LIST, {})
      .then(onOperatorListSuccess)
      .catch(onError);  

  }, []);

  function onOperatorListSuccess(resp) {
    setOperatorsList(resp);
  }

  useEffect(() => {
    if (props.countryCodeId) {
      const params = {
        countryCode: props.countryCodeId,
      };
      postApiCall(API_STATES_LIST, params)
        .then((response) => {
          setStateCode(response);
        })
        .catch((error) => console.error("Error while fetching states:", error));
    }
  }, [props.countryCodeId]);

  function onCountryCodesListSuccess(resp) {
    setCountryCodesList(resp);
  }


  function onError(err) {
    console.log(err);
  }

  return (
    <>
      <div className="row">
     
        <Form.Group className="col-md-6 form-group">
          <Form.Select
            id="formatType"
            value={props.formatType}
            className="p-2"
            onChange={(e) => props.setFormatType(e.target.value)}
            required
          >
            <option value={""}>Select Format Type</option>
            {NUMBERS_FORMAT_TYPES.map(function (val, idx) {
              return (
                <option key={idx} value={val.value}>
                  {val.label}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className="col-md-6 form-group">
          <Form.Select
            id="numberType"
            value={props.numberType}
            className="p-2"
            onChange={(e) =>props.setNumberType(e.target.value)}
            required
          >
             <option value={""}>Select Number Type</option>
            {NUMBERS_TYPES.map(function(val,idx){
              return(
                <option key ={idx} value={val.value}>
                  {val.label}
                </option>
              )
            })}
          </Form.Select>
        </Form.Group>
        {props.numberType === 'DID' && (
          <Form.Group className="col-md-6 form-group">
            <Form.Select
              id="category"
              value={props.category}
              className="p-2"
              onChange={(e) =>props.setCategory(e.target.value)}
              required
            >
              <option value="">Select Category</option>
              <option value="Free">Free</option>
              <option value="Paid">Paid</option>
            </Form.Select>
          </Form.Group>
        )}
        {(props.numberType === 'DID' || props.numberType === 'VMN' ||  props.numberType === 'TOLL FREE') && (
          <Form.Group className="col-md-6 form-group">
          <Form.Select
            id="currency"
            value={props.currency}
            className="p-2"
            onChange={(e) => props.setCurrency(e.target.value)}
            required
          >
            <option value="">Select Currency</option>
            <option value="INR">INR</option>
            <option value="USD">USD</option>
          </Form.Select>
        </Form.Group>
        )}


        {(props.numberType === 'DID' || props.numberType === 'VMN' || props.numberType === 'TOLL FREE') && props.formatType !== NUMBERS_FORMAT_FILE && (
        <Form.Group className="col-md-6 form-group">
          <Form.Select
            id="countryCodeId"
            required
            value={props.countryCodeId}
            className="p-2"
            onChange={(e) => props.setCountryCodeId(e.target.value)}
          >
            <option value={""}>Select Country Code</option>
            {countryCodesList.map(function (val, idx) {
              return (
                <option key={idx} value={val.code}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
          )}
    {(props.numberType === "DID" && props.formatType === NUMBERS_FORMAT_INDIVIDUAL) && (
          <Form.Group className="col-md-6 form-group">
          <Form.Select
            id="stateCode"
            required
            value={props.stateCode}
            className="p-2"
            onChange={(e) => props.setStateCode(e.target.value)}
          >
            <option value={""}>Select State Code</option>
            {stateCodeList && stateCodeList.map(function (val, idx) {
              return (
                <option key={idx} value={val.isoCode}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        )}
      </div>

      <div className="row">
        {(props.formatType === NUMBERS_FORMAT_INDIVIDUAL) && (
        <Form.Group className="col-md-6 form-group">
        <Form.Select
          id="operator"
          placeholder="Operator"
          className="p-2"
          required
          value={props.operator}
          onChange={(e) => props.setOperator(e.target.value)}
        >
          <option value={""}>Select Operator</option>
          {operatorsList.map(function (val, idx) {
            return (
              <option key={idx} value={val.name}>
                {val.name}
              </option>
            );
          })}
        </Form.Select>
        </Form.Group>
        )}
     
        <RenderFormatTypeFields props={props}/>
      </div>
      <hr />
    </>
  );
}
