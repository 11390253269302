import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function RenderDeleteModal(props){

    const handleClose = () => {
        props.setShow(false);
        props?.setConfirmDelete(false);
        if (props?.isMultiDelete) {
            props?.clearSelectedData();
        }
    };
    const handleShow = () => props.setShow(true);  
    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete record?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this record?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={() => props.setConfirmDelete(true)}>Yes</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default RenderDeleteModal;