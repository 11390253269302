const downloadTemplate = (event,columns, filename) => {
    event.preventDefault();
    
    const csvContent = `data:text/csv;charset=utf-8,${columns.join(',')}\n`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link); 
    link.click();
    document.body.removeChild(link);
  };

export default downloadTemplate;