import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utilities/constant";

const SwitchAccountLoader = () => {

    const navigate = useNavigate();

    useEffect( () => {

        setTimeout( () => {

            navigate(`/${ROUTES.USERS.LIST}`)

        }, 1500);

    }, []);

    return (
        <div className="loader simple-loader"><div className="loader-body"></div></div>
    );

}

export default SwitchAccountLoader;