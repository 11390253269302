import React, { useState, useEffect } from "react";
import Card from "../../../components/Card";
import { Row, Col, Button } from "react-bootstrap";
import ToastNotification from "../../../components/Toast";
import { postApiCall } from "../../../service/httpCall";
import { API_SERVER_MANAGEMENT_CREATE } from "../../../service/url";
import { useNavigate } from "react-router-dom";
import {
  ROUTES,
  SERVER_MANAGEMENT_DOMESTIC_TYPE,
  SERVER_MANAGEMENT_INTERNATIONAL,
} from "../../../utilities/constant";
import RenderServerManagementForm from "./_form";
import { useAuth, errorCheck } from '../auth/auth-utils';

export default function ServerManagementCreate() {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [dataCenterId, setDataCenterId] = useState("");
  const [serverType, setServerType] = useState("");
  const [serverIP, setServerIP] = useState("");
  const [serverName, setServerName] = useState("");
  const [OS, setOS] = useState("");
  const [CPUCores, setCPUCores] = useState("");
  const [RAM, setRAM] = useState("");
  const [hardDisk, setHardDisk] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [countryData, setCountryData] = useState({});
  const [stateData, setStateData] = useState({});

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    checkAuth(setToastProps, setShowToast)
  }, []);

  function validateForm() {
    let valid = true;

    if (!dataCenterId) {
      valid = false;
      setToastProps({ message: "Data Center required", type: "danger" });
    } else if (!serverType) {
      valid = false;
      setToastProps({ message: "Server Type required", type: "danger" });
    } else if (!serverIP.trim()) {
      valid = false;
      setToastProps({ message: "Server IP required", type: "danger" });
    } else if (!serverName.trim()) {
      valid = false;
      setToastProps({ message: "Server Name required", type: "danger" });
    } else if (!OS.trim()) {
      valid = false;
      setToastProps({ message: "OS required", type: "danger" });
    } else if (!CPUCores.trim()) {
      valid = false;
      setToastProps({ message: "CPU Cores required", type: "danger" });
    } else if (!RAM.trim()) {
      valid = false;
      setToastProps({ message: "RAM required", type: "danger" });
    } else if (!hardDisk.trim()) {
      valid = false;
      setToastProps({ message: "Hard Disk required", type: "danger" });
    }

    return valid;
  }

  function submitForm(e) {
    e.preventDefault();

    setButtonDisable(true);

    if (!validateForm()) {
      setButtonDisable(false);
      return;
    }

    let params = {
      data_center: dataCenterId,
      type: Number(serverType),
      server_ip: serverIP,
      server_name: serverName,
      os: OS,
      cpu_cores: CPUCores,
      ram: RAM,
      hard_disk: hardDisk,
    };

    postApiCall(API_SERVER_MANAGEMENT_CREATE, params)
      .then(onServerCreateSuccess)
      .catch(onServerCreateError);
  }

  function onServerCreateSuccess(resp) {
    setToastProps({ message: "Server created successfully", type: "success" });
    setTimeout(() => {
      navigate(`/${ROUTES.SERVER_MANAGEMENT.LIST}`);
    }, 1500);
  }

  function onServerCreateError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   }
    setButtonDisable(false);
    setToastProps({ message: err, type: "danger" });
  }

  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     setButtonDisable(false);
     setToastProps({message: JSON.stringify(err), type: 'danger'});
   }
 }


  return (
    <>
      <div>
        <Row>
          <Col xl="12" lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">
                    New Server Management Information
                  </h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-trunk-info"></div>

                <form
                  onSubmit={async (e) => {
                    submitForm(e);
                  }}
                >
                  <RenderServerManagementForm
                    dataCenterId={dataCenterId}
                    setDataCenterId={setDataCenterId}
                    serverType={serverType}
                    setServerType={setServerType}
                    serverIP={serverIP}
                    setServerIP={setServerIP}
                    serverName={serverName}
                    setServerName={setServerName}
                    OS={OS}
                    setOS={setOS}
                    CPUCores={CPUCores}
                    setCPUCores={setCPUCores}
                    RAM={RAM}
                    setRAM={setRAM}
                    hardDisk={hardDisk}
                    setHardDisk={setHardDisk}
                    countryData={countryData}
                    setCountryData={setCountryData}
                    stateData={stateData}
                    setStateData={setStateData}
                  />

                  <Button
                    type={"submit"}
                    variant="btn btn-primary"
                    disabled={buttonDisable}
                  >
                    Add Server &nbsp;
                    {buttonDisable ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <></>
                    )}
                  </Button>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
}
