import axios from "axios";

const CONTENT_TYPE = "application/json";
const TIMEOUT = 10000;

export const apiWithoutHeader = (baseUrl) =>
  axios.create({
    baseURL: baseUrl,
    timeout: TIMEOUT,
    headers: {
      "Content-Type": CONTENT_TYPE
    }
  });

export const apiWithGetHeader = (baseUrl, payload, token) => {

  return axios.create({
    baseURL: baseUrl,
    timeout: TIMEOUT,
    params: payload,
    headers: {
      "Content-Type": CONTENT_TYPE,
      "Authorization": token
    }
  });

}


export const apiWithHeader = (baseUrl, CONTENT_TYPE, token) =>
  axios.create({
    baseURL: baseUrl,
    method: "post",
    timeout: TIMEOUT,
    headers: {
      "Content-Type": CONTENT_TYPE,
      "Access-Control-Request-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      "Authorization": token
    },
    data: {}
  });

export const apiWithCustomHeader = (headerparams, baseUrl) =>
  axios.create({
    baseURL: baseUrl,
    timeout: TIMEOUT,
    headers: headerparams
  });
