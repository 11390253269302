import React, { useState, useEffect } from 'react'
import Card from '../../../components/Card'
import { Row, Col, Button } from 'react-bootstrap'

import ToastNotification from '../../../components/Toast';
import { postApiCall } from '../../../service/httpCall';
import { API_TRUNKS_CREATE } from '../../../service/url';
import { useNavigate } from 'react-router-dom';
import { ROUTES, TRUNK_AUTH_TYPE_IP, TRUNK_AUTH_TYPE_REGISTRATION } from '../../../utilities/constant';
import RenderTrunkForm from './_form';
import { validatePhone } from '../../../utilities/helpers';
import { useAuth, errorCheck } from '../auth/auth-utils';

export default function TrunkCreate() {

   const navigate = useNavigate();
   const { checkAuth } = useAuth();
   const [name, setName] = useState('');
   const [authType, setAuthType] = useState('');
   const [domain, setDomain] = useState('');
   const [port, setPort] = useState('');
   const [codec, setCodec] = useState('');
   const [pilotNumber, setPilotNumber] = useState('');
   const [operator, setOperator] = useState('');
   const [channels, setChannels] = useState(0);
   const [cps, setCps] = useState(0);
   const [status, setStatus] = useState('');
   const [server, setServer] = useState('');


   const [authUsername, setAuthUsername] = useState('');
   const [authPassword, setAuthPassword] = useState('');
   const [authServerUrl, setAuthServerUrl] = useState('');
   const [authClientUrl, setAuthClientUrl] = useState('');

   const [ipIdentifyBy, setIpIdentifyBy] = useState('');

   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);

   useEffect(() => {

      if (toastProps) {
         setShowToast(true);
      }

   }, [toastProps]);

   useEffect(() => {
      checkAuth(setToastProps, setShowToast)
    }, []);


   function validateForm() {

      let valid = true;

      if (!name.trim()) {
         valid = false;
         setToastProps({ message: 'Name required', type: 'danger' });
      }
      else if (!authType) {
         valid = false;
         setToastProps({ message: 'Authentication required', type: 'danger' });
      }
      else if (!domain.trim()) {
         valid = false;
         setToastProps({ message: 'Domain required', type: 'danger' });
      }
      else if (isNaN(port) || port < 1024 || port > 65535) {
         valid = false;
         setToastProps({ message: 'Invalid port value', type: 'danger' });
      }
      else if (authType == TRUNK_AUTH_TYPE_IP && !ipIdentifyBy.trim()) {
         valid = false;
         setToastProps({ message: 'Identify By required', type: 'danger' });
      }
      else if (authType == TRUNK_AUTH_TYPE_REGISTRATION) {
         if (!authUsername.trim()) {
            valid = false;
            setToastProps({ message: 'Authentication username required', type: 'danger' });
         }
         else if (!authPassword.trim()) {
            valid = false;
            setToastProps({ message: 'Authentication password required', type: 'danger' });
         }
         else if (!authClientUrl.trim()) {
            valid = false;
            setToastProps({ message: 'Authentication client url required', type: 'danger' });
         }
         else if (!authServerUrl.trim()) {
            valid = false;
            setToastProps({ message: 'Authentication server url required', type: 'danger' });
         }
      }
      else if(!codec){
         valid = false;
         setToastProps({ message: 'Codec required', type: 'danger' });
      }
      else if(isNaN(channels) && channels < 0){
         valid = false;
         setToastProps({ message: 'Invalid channel value', type: 'danger' });
      }
      else if(isNaN(cps) && cps < 0){
         valid = false;
         setToastProps({ message: 'Invalid calls per sec value', type: 'danger' });
      }
      else if(isNaN(status)){
         valid = false;
         setToastProps({ message: 'Status required', type: 'danger' });
      }
      else if(!server){
         valid = false;
         setToastProps({ message: 'Server required', type: 'danger' });
      }

      if(!validatePhone(pilotNumber)){
         valid = false;
         setToastProps({ message: 'Invalid Pilot number format', type: 'danger' });
      }

      return valid;

   }

   async function submitForm(e) {

      e.preventDefault();

      setButtonDisable(true);

      if (!validateForm()) {
         setButtonDisable(false);
         return;
      }

      let params = {
         name, auth_type: authType, domain, port, codec, pilot_number: pilotNumber, operator, channels, cps, status, server
      }
      if(authType == TRUNK_AUTH_TYPE_REGISTRATION){
         const authTypeRegistration = { username: authUsername, password: authPassword, client_url: authClientUrl, server_url: authServerUrl }
         params = {...params, auth_type_registration: authTypeRegistration}
      }
      else if(authType == TRUNK_AUTH_TYPE_IP){
         params = {...params, auth_type_identify_by: ipIdentifyBy}
      }

      postApiCall(API_TRUNKS_CREATE, params)
         .then(onTrunkCreateSuccess)
         .catch(onTrunkCreateError);

   }

   function onTrunkCreateSuccess(resp) {
      setToastProps({ message: 'Trunk created successfully', type: 'success' });
      setTimeout(() => {
         navigate(`/${ROUTES.TRUNKS.LIST}`);
      }, 1500);
   }

   function onTrunkCreateError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        setButtonDisable(false);
        setToastProps({message: JSON.stringify(err), type: 'danger'});
      }
   }

   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
       errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
      setButtonDisable(false);
      setToastProps({message: JSON.stringify(err), type: 'danger'});
    }
   }

   return (
      <>

         <div>
            <Row>
               <Col xl="12" lg="12">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">New Trunk Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>

                        <div className="new-trunk-info"></div>

                        <form onSubmit={async (e) => { submitForm(e) }}>

                           <RenderTrunkForm
                              name={name} setName={setName}
                              authType={authType} setAuthType={setAuthType}
                              domain={domain} setDomain={setDomain}
                              port={port} setPort={setPort}
                              codec={codec} setCodec={setCodec}
                              channels={channels} setChannels={setChannels}
                              cps={cps} setCps={setCps}
                              pilotNumber={pilotNumber} setPilotNumber={setPilotNumber}
                              operator={operator} setOperator={setOperator}
                              status={status} setStatus={setStatus}
                              authUsername={authUsername} setAuthUsername={setAuthUsername}
                              authPassword={authPassword} setAuthPassword={setAuthPassword}
                              authClientUrl={authClientUrl} setAuthClientUrl={setAuthClientUrl}
                              authServerUrl={authServerUrl} setAuthServerUrl={setAuthServerUrl}
                              ipIdentifyBy={ipIdentifyBy} setIpIdentifyBy={setIpIdentifyBy}
                              server={server} setServer={setServer}
                           />

                           <Button type={'submit'} variant="btn btn-primary" disabled={buttonDisable}>
                              Add New Trunk &nbsp;
                              {
                                 buttonDisable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>
                              }
                           </Button>

                        </form>

                     </Card.Body>
                  </Card>
               </Col>
            </Row>

         </div>


         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

      </>
   )

}