import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import { getApiCall,postApiCall,deleteApiCallWithHeader} from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import { ROUTES, USER_STATUS_LABEL } from '../../../utilities/constant';
import { EditIcon, DeleteIcon } from '../../../components/setting/elements';
import { API_MODULE_LIST, API_MODULE_DELETE } from '../../../service/url';
import Loader  from '../../../components/partials/components/loader';
import { Checkbox } from 'primereact/checkbox';
import "../../../assets/custom/css/custom.css";
//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';
import { useAuth, errorCheck } from '../auth/auth-utils';
import { useNavigate } from "react-router-dom";
import exportData from '../../../utilities/export';


export default function ModuleList() {
   const { checkAuth } = useAuth();
   const navigate = useNavigate();
   const [module,setModule] = useState([]);
   const [filters, setFilters] = useState({
      module_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [deleteModuleId, setDeleteModuleId] = useState(undefined);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [loading, setLoading] = useState(false);
   const [selectedModule, setSelectedModule] = useState([]);
   const [isMultiDelete, setIsMultiDelete] = useState(false);
   const [buttonDisable, setButtonDisable] = useState(false);

   const handleExport = async () => {
      setButtonDisable(true); 
      await exportData('Module', setToastProps, setShowToast);
      setButtonDisable(false); 
   };
  
    const handleCheckboxToggle = (moduleId) => {
      const isSelected = selectedModule.includes(moduleId);
      setSelectedModule(isSelected
          ? selectedModule.filter(id => id !== moduleId)
          : [...selectedModule, moduleId]);
    };
  
    const handleMultiDelete = () => {
          setIsMultiDelete(true);
          setEnableDeleteModal(true);
    };

   useEffect(() => {

      if (toastProps) {
         setShowToast(true);
      }

   }, [toastProps]);

   useEffect(() => {
      setLoading(true);
      getApiCall(API_MODULE_LIST)
         .then(onModuleListSuccess)
         .catch(onError)
         .finally(() => setLoading(false));

   }, []);


   function onModuleListSuccess(resp) {
      const updatedList = resp.map((val, idx) => {
         val['key'] = idx + 1;
         // val['actions'] = RenderActionComponent(val._id);
         val['createdAt'] = new Date(val['createdAt']).toGMTString();
         return val;
      });
      setModule(updatedList);
   }

   function handleDeleteModule(id) {
      checkAuth(setToastProps, setShowToast);
      setIsMultiDelete(false);
      setEnableDeleteModal(true);
      setDeleteModuleId(id);
   }

   const handleSelectAll = () => {
      if (selectedModule.length === module.length) {
          setSelectedModule([]);
      } else {
          const allModuleIds = module.map(allModuleIds => allModuleIds._id);
          setSelectedModule(allModuleIds);
      }
    };

   useEffect( () => {
      if (confirmDelete) {
          if (isMultiDelete) {
              postApiCall(API_MODULE_DELETE, { moduleIds: selectedModule })
                  .then(onDeleteSuccess)
                  .catch(onDeleteFail);
          } else {
              postApiCall(API_MODULE_DELETE, { moduleIds: [deleteModuleId] })
                  .then(onDeleteSuccess)
                  .catch(onDeleteFail);
          }
      }
      }, [confirmDelete]);



   function onDeleteSuccess(resp) {
      setToastProps({ type: 'success', message: 'Module deleted successfully' });
      let updatedList;
      if (isMultiDelete) {
        updatedList = module.filter(val => !selectedModule.includes(val._id)).map((val, key) => {
            val['key'] = key + 1;
            return val;
        });
        setSelectedModule([]);
    } else {
        updatedList = module.filter(val => val._id != deleteModuleId).map((val, key) => {
         val['key'] = key + 1;
         return val;
      });
    }
      setModule(updatedList);
      setEnableDeleteModal(false);
      setDeleteModuleId(undefined);
      setConfirmDelete(false); 
   }

   function onDeleteFail(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
         setToastProps({ type: 'danger', message: `Unable to delete module: ${err}` });
      }
      setConfirmDelete(false); 
   }

   const RenderActionComponent = ({id}) => {
      const moduleEditRoute = ROUTES.MODULE.EDIT.replace(':id', id);
      return (
         <div className="flex align-items-center list-user-action">
            <EditIcon linkTo={`/${moduleEditRoute}`} />
            {' '}
            {!(selectedModule.length > 0) && (
                <DeleteIcon handleCallback={() => handleDeleteModule(id)} />
             )}
            {' '}
         </div>
      );

   }

   const dropdownItemTemplate = (option) => {
      return <span>{option}</span>;
   }

   const statusFilterTemplate = (options) => {
      return <Dropdown value={options.value} options={USER_STATUS_LABEL} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
   }

  

   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        console.log(err)
      }
   }

   const handleCreateButtonClick = () => {
      navigate(`/${ROUTES.MODULE.CREATE}`);
   } 

   const rowClassName = (data) => {
      return {
          'highlighted-row': selectedModule.includes(data._id)
        };
    }

    const actionBodyTemplate = (rowData) => {
      return <RenderActionComponent id={rowData._id} />;
    };

   if (loading) {
      return <Loader />
   }

   return (
      <>
         <div>
            <Row>
               <Col sm="12">
                  <Card>
                     <Card.Header className="d-block justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Module List</h4>
                        </div>
                        <button className="btn btn-primary ml-auto" onClick={handleCreateButtonClick}>Create Module</button>
                     </Card.Header>
                     <Card.Body className="px-0">
                        <Button 
                           label="Delete Selected" 
                           onClick={handleMultiDelete} 
                           className="p-button-danger p-button-sm" 
                           style={{ marginBottom: '10px', marginLeft: '10px' }} 
                           disabled={selectedModule.length === 0} 
                        />
                        <Button 
                           label="Export Data" 
                           onClick={handleExport} 
                           className="p-button-active p-button-sm" 
                           style={{ marginBottom: '10px', marginLeft: '10px' }} 
                           disabled={buttonDisable} 
                        >
                           &nbsp;
                           {buttonDisable && (
                              <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                           )}
                        </Button>
                        <div className="table-responsive border-bottom my-3">
                           <DataTable value={module} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8} rowClassName={rowClassName}>
                              <Column 
                                 header={<Checkbox 
                                 checked={selectedModule.length === module.length && module.length > 0} 
                                 onChange={handleSelectAll} 
                                 />} 
                                 headerStyle={{ width: '3rem' }} 
                                 body={(rowData) => (
                                 <Checkbox 
                                    checked={selectedModule.includes(rowData._id)} 
                                    onChange={() => handleCheckboxToggle(rowData._id)} 
                                 />
                                 )} 
                              />
                              <Column field="module_name" filterField="module_name" header="Module Name" filter filterPlaceholder="Search by Module name" ></Column>
                              <Column field="status" header="Status" filterElement={statusFilterTemplate} filter></Column>
                              <Column field="createdAt" header="Created Date"></Column>
                              <Column header="Action" body={actionBodyTemplate} />
                           </DataTable>
                        </div>

                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>

         <RenderDeleteModal 
            show={enableDeleteModal} 
            setShow={setEnableDeleteModal} 
            setConfirmDelete={setConfirmDelete} 
            isMultiDelete={isMultiDelete} 
            clearSelectedData={() => setSelectedModule([])}
         />

         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

      </>
   )

}