import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import {  API_MODULE_LIST } from "../../../service/url";
import { getApiCall } from "../../../service/httpCall";
import {STATUS} from "../../../utilities/constant";



export default function RenderModuleForm(props) {
  
  const [moduleList, setModuleList] = useState([]);

  useEffect(() => {
      getApiCall(API_MODULE_LIST, {})
      .then(onModuleListSuccess)
      .catch(onError);  
  }, []);


  function onModuleListSuccess(resp) {
    setModuleList(resp);
}

  function onError(err) {
    console.log(err);
  }

  return (
    <>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && <Form.Label htmlFor="name">Module Name:</Form.Label>}
          <Form.Control
            type="text"
            id="module_name"
            placeholder="Module Name"
            maxLength={100}
            value={props.moduleName}
            onChange={(e) => props.setModuleName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Status:</Form.Label>
          )}
          <Form.Select
            id="status"
            className="p-2"
            required
            value={props.status}
            onChange={(e) => props.setStatus(e.target.value)}
          >
            <option value={""}>Status</option>
            {STATUS.map(function (val, idx) {
              return (
                <option key={idx} value={val.value}>
                  {val.label}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </div>
      
    </>
  );
}
