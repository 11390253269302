import React, { useState, useEffect } from 'react'
import Card from '../../../components/Card'
import { Row, Col, Button } from 'react-bootstrap'

import ToastNotification from '../../../components/Toast';
import { postApiCall } from '../../../service/httpCall';
import { API_MODULE_CREATE } from '../../../service/url';
import { useNavigate } from 'react-router-dom';
import { ROUTES} from '../../../utilities/constant';
import { validatePhone } from '../../../utilities/helpers';
import { useAuth, errorCheck } from '../auth/auth-utils';
import RenderModuleForm from './_form';


export default function ModuleCreate() {

   const navigate = useNavigate();
   const { checkAuth } = useAuth();
   const [moduleName, setModuleName] = useState('');
   const [status, setStatus] = useState('');
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);

   useEffect(() => {

      if (toastProps) {
         setShowToast(true);
      }

   }, [toastProps]);

   useEffect(() => {
      checkAuth(setToastProps, setShowToast)
    }, []);


   function validateForm() {

      let valid = true;

      if (!moduleName.trim()) {
         valid = false;
         setToastProps({ message: 'Module Name required', type: 'danger' });
      }
      else if(isNaN(status)){
         valid = false;
         setToastProps({ message: 'Status required', type: 'danger' });
      }

      return valid;

   }

   async function submitForm(e) {

      e.preventDefault();

      setButtonDisable(true);

      if (!validateForm()) {
         setButtonDisable(false);
         return;
      }

      let params = {
         module_name:moduleName,  status:status
      }

      postApiCall(API_MODULE_CREATE, params)
         .then(onModuleCreateSuccess)
         .catch(onModuleCreateError);

   }

   function onModuleCreateSuccess(resp) {
      setToastProps({ message: 'Module created successfully', type: 'success' });
      setTimeout(() => {
         navigate(`/${ROUTES.MODULE.LIST}`);
      }, 1500);
   }

   function onModuleCreateError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        setButtonDisable(false);
        setToastProps({message: JSON.stringify(err), type: 'danger'});
      }
   }

   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
       errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
      setButtonDisable(false);
      setToastProps({message: JSON.stringify(err), type: 'danger'});
    }
   }

   return (
      <>

         <div>
            <Row>
               <Col xl="12" lg="12">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">New Module Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>

                        <div className="new-trunk-info"></div>

                        <form onSubmit={async (e) => { submitForm(e) }}>

                           <RenderModuleForm
                              moduleName={moduleName} setModuleName={setModuleName}
                              status={status} setStatus={setStatus}
                             
                           />

                           <Button type={'submit'} variant="btn btn-primary" disabled={buttonDisable}>
                              Add New Module &nbsp;
                              {
                                 buttonDisable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>
                              }
                           </Button>

                        </form>

                     </Card.Body>
                  </Card>
               </Col>
            </Row>

         </div>


         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

      </>
   )

}


