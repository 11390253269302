import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../utilities/constant';
import Default from "../layouts/dashboard/default";
import Loader  from '../components/partials/components/loader';

const DefaultRouter1 = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkLoginStatus = async () => {
             try {
                const token = await AsyncStorage.getItem('access_token');
                if (token) {
                    setIsLoggedIn(true);
                }
            } catch (error) {
                console.error('Error fetching token', error);
            } finally {
                setLoading(false);
            }
        };

        checkLoginStatus();
    }, []);

    if (loading) {
        return <Loader /> 
    }

    if (isLoggedIn) {
        return <Default />;
    }

    return <Navigate to={`/${ROUTES.LOGIN}`} />
};

export default DefaultRouter1;