//react-bootstrap
import { Dropdown } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
// Redux Selector / Action
import { useSelector } from 'react-redux';
// Import selectors & action from setting store
import * as SettingSelector from '../../../store/setting/selectors'

const RenderUserAccessRolesList = ({activeUserAccessRole, userAccessRoles, setActiveUserAccessRole}) => {

    function RenderUserAccessRoleItems(){

        return (
            userAccessRoles.map( (role, idx) => {
                return (<Dropdown.Item key={idx} eventKey={role.value} value={role.value} active={role.value == activeUserAccessRole}>{role.label}</Dropdown.Item>);
            } )
        );

    }

    if(activeUserAccessRole && userAccessRoles){

        if(userAccessRoles.length > 1){
            return (
                <DropdownButton onSelect={setActiveUserAccessRole} id="dropdown-basic-button" title="Select role">
                    <RenderUserAccessRoleItems/>
                </DropdownButton>
            );
        }
        else if (userAccessRoles.length == 1){
           return (<span>{userAccessRoles[0].label}</span>);
        }

     }

     return (<></>);

}

export default RenderUserAccessRolesList;