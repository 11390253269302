// import { createStore, combineReducers } from 'redux'
// import Mode from './setting/setting'
// export default createStore(
//     combineReducers({
//         mode: Mode
//     })
// )


import { configureStore } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage
}
const persistedReducer = persistReducer(persistConfig, settingReducer);

export const store = configureStore({
  reducer: {
    setting: persistedReducer,
    //user: userSliceReducer
  },
  middleware: [thunk]
});

export const persistor = persistStore(store)
//export const { setUser, clearUser } = userSliceReducer.actions;
