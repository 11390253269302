import React, { useState, useEffect, useRef } from 'react'
import {Row,Col,Image} from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Card from '../../../components/Card'
import { API_IVR_LIST, API_IVR_DELETE } from '../../../service/url';
import { getApiCall, deleteApiCallWithHeader , postApiCall} from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import ToastNotification from '../../../components/Toast';
import { ROUTES } from '../../../utilities/constant';
import { EditIcon, DeleteIcon } from '../../../components/setting/elements';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import Loader  from '../../../components/partials/components/loader';
import { useAuth, errorCheck } from '../auth/auth-utils';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import "../../../assets/custom/css/custom.css";
import exportData from '../../../utilities/export';
//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';


const IvrList = () => {
   const { checkAuth } = useAuth();
   const navigate = useNavigate();
   const { cid } = useParams();

   const [IvrList, setIvrList] = useState([]);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [filters, setFilters] = useState({
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
   });
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [deleteIvrId, setDeleteIvrId] = useState(undefined);
   const [loading, setLoading] = useState(false);
   const [selectedIVR, setSelectedIVR] = useState([]);
   const [isMultiDelete, setIsMultiDelete] = useState(false);
   const [buttonDisable, setButtonDisable] = useState(false);

   const handleExport = async () => {
      setButtonDisable(true); 
      await exportData('IVR', setToastProps, setShowToast);
      setButtonDisable(false); 
   };
  
    const handleCheckboxToggle = (ivrId) => {
      const isSelected = selectedIVR.includes(ivrId);
      setSelectedIVR(isSelected
          ? selectedIVR.filter(id => id !== ivrId)
          : [...selectedIVR, ivrId]);
    };
  
    const handleMultiDelete = () => {
          setIsMultiDelete(true);
          setEnableDeleteModal(true);
    };


   useEffect( () => {
      setLoading(true);
      const apiIvrListUrl = API_IVR_LIST.replace(':cid', cid);
      getApiCall(apiIvrListUrl, {})
      .then(onIvrListSuccess)
      .catch(onError)
      .finally(() => setLoading(false));

   }, []);

   useEffect( () => {

      if(toastProps){
         setShowToast(true);
      }

   }, [toastProps]);

   const handleSelectAll = () => {
      if (selectedIVR.length === IvrList.length) {
          setSelectedIVR([]);
      } else {
          const allAclIds = IvrList.map(ivr => ivr._id);
          setSelectedIVR(allAclIds);
      }
    };

    useEffect( () => {
      if (confirmDelete) {
          if (isMultiDelete) {
              postApiCall(API_IVR_DELETE, { ivrIds: selectedIVR, cid: cid })
                  .then(onDeleteSuccess)
                  .catch(onDeleteFail);
          } else {
              postApiCall(API_IVR_DELETE, { ivrIds: [deleteIvrId] , cid: cid})
                  .then(onDeleteSuccess)
                  .catch(onDeleteFail);
          }
      }
      }, [confirmDelete]);

    function onDeleteSuccess(resp){
        setToastProps({type: 'success', message: 'IVR deleted successfully'});
        let updatedList;
         if (isMultiDelete) {
            updatedList = IvrList.filter(val => !selectedIVR.includes(val._id)).map((val, key) => {
                  val['key'] = key + 1;
                  return val;
            });
         } else {
            updatedList = IvrList.filter(val => val._id != deleteIvrId).map( (val, key) => {
               val['key'] = key+1;
               return val;
            });
         }
        setIvrList(updatedList);
        setEnableDeleteModal(false);
        setDeleteIvrId(undefined);
     }
  
     function onDeleteFail(err){
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        setToastProps({type: 'danger', message: `Unable to delete IVR: ${err}`});
      }
     }


   function onIvrListSuccess(resp){
      for(let idx in resp){
        resp[idx]['key'] = Number(idx)+1;
      //   resp[idx]['actions'] = RenderActionComponent(resp[idx]._id);
        resp[idx]['createdAt'] = new Date(resp[idx]['createdAt']).toGMTString();
      }
      setIvrList(resp);
   }

    function handleDeleteIVR(id){
      checkAuth(setToastProps, setShowToast)
      setIsMultiDelete(false);
        setEnableDeleteModal(true);
        setDeleteIvrId(id);
    }

    const RenderActionComponent = ({id}) => {
      const editUrl = ROUTES.IVR.EDIT.replace(':id', id).replace(':cid', cid);

      return (
        <div className="flex align-items-center list-user-action">
            <EditIcon linkTo={`/${editUrl}`}/>
            {' '}
             {!(selectedIVR.length > 0) && (
                <DeleteIcon handleCallback={() => handleDeleteIVR(id)} />
             )}
            {' '}
        </div>
      );

   }

//    const dropdownItemTemplate = (option) => {
//       return <span>{option}</span>;
//    }

//    const statusFilterTemplate = (options) => {
//       return <Dropdown value={options.value} options={statuses.current} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
//    }

function onError(err) {
   if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     console.log(err)
   }
}

const handleCreateButtonClick = () => {
   navigate(`/${ROUTES.IVR.CREATE}`);
}

const rowClassName = (data) => {
   return {
       'highlighted-row': selectedIVR.includes(data._id)
     };
 }


 const actionBodyTemplate = (rowData) => {
   return <RenderActionComponent id={rowData._id} />;
};
   
 if (loading) {
   return <Loader />
}

  return(
     <>
       <div>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">IVR List</h4>
                     </div>
                     <button className="btn btn-primary ml-auto" onClick={handleCreateButtonClick}>Create IVR</button>
                  </Card.Header>
                  <Card.Body className="px-0">
                     
                     <div className="table-responsive border-bottom my-3">
                        <Button 
                           label="Delete Selected" 
                           onClick={handleMultiDelete} 
                           className="p-button-danger p-button-sm" 
                           style={{ marginBottom: '10px', marginLeft: '10px' }} 
                           disabled={selectedIVR.length === 0} 
                        />
                        <Button 
                           label="Export Data" 
                           onClick={handleExport} 
                           className="p-button-active p-button-sm" 
                           style={{ marginBottom: '10px', marginLeft: '10px' }} 
                           disabled={buttonDisable} 
                        >
                           &nbsp;
                           {buttonDisable && (
                              <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                           )}
                        </Button>
                        <DataTable value={IvrList} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8} rowClassName={rowClassName}>
                           <Column 
                              header={<Checkbox 
                              checked={selectedIVR.length === IvrList.length && IvrList.length > 0} 
                              onChange={handleSelectAll} 
                              />} 
                              headerStyle={{ width: '3rem' }} 
                              body={(rowData) => (
                              <Checkbox 
                                 checked={selectedIVR.includes(rowData._id)} 
                                 onChange={() => handleCheckboxToggle(rowData._id)} 
                              />
                              )} 
                           />
                           <Column field="name" filterField="name" header="name" filter filterPlaceholder="Search by name" ></Column>
                           <Column field="menu_wait_time" header="Menu wait time" ></Column>
                           <Column field="reprompt" header="Reprompt"></Column>
                           <Column field="createdAt" header="Created Date"></Column>
                           <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                     </div>

                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </div>

      <RenderDeleteModal 
         show={enableDeleteModal} 
         setShow={setEnableDeleteModal} 
         setConfirmDelete={setConfirmDelete} 
         isMultiDelete={isMultiDelete} 
         clearSelectedData={() => setSelectedIVR([])}
      />

      <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

     </>
  )

}

export default IvrList;