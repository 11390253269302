import React, { useState, useEffect, useRef } from 'react'
import {Row,Col,Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Card from '../../../components/Card'
import { API_ACL_SETTING_LIST, API_ACL_SETTING_DELETE } from '../../../service/url';
import { getApiCall, deleteApiCallWithHeader } from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import { ROUTES } from '../../../utilities/constant';
import Loader  from '../../../components/partials/components/loader';
import { useNavigate } from "react-router-dom";
import { useAuth, errorCheck } from '../auth/auth-utils';
import { EditIcon, DeleteIcon } from '../../../components/setting/elements';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import "../../../assets/custom/css/custom.css";
import { postApiCall} from "../../../service/httpCall";
import exportData from '../../../utilities/export';

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';

const ACLSettingList = () =>{
   const navigate = useNavigate();
   const { checkAuth } = useAuth();
   const [aclSettingList, setAClSettingList] = useState([]);
   const [filters, setFilters] = useState({
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
   });
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [deleteACLId, setDeleteACLId] = useState(undefined);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [loading, setLoading] = useState(false);
   const [selectedAcl, setSelectedAcl] = useState([]);
   const [isMultiDelete, setIsMultiDelete] = useState(false);
   const [buttonDisable, setButtonDisable] = useState(false);

   const handleExport = async () => {
      setButtonDisable(true); 
      await exportData('AclSetting', setToastProps, setShowToast);
      setButtonDisable(false); 
   };
  
    const handleCheckboxToggle = (aclId) => {
      const isSelected = selectedAcl.includes(aclId);
      setSelectedAcl(isSelected
          ? selectedAcl.filter(id => id !== aclId)
          : [...selectedAcl, aclId]);
    };
  
    const handleMultiDelete = () => {
          setIsMultiDelete(true);
          setEnableDeleteModal(true);
    };


   useEffect( () => {

      if(toastProps){
         setShowToast(true);
      }

   }, [toastProps]);

   useEffect(() => {
      setLoading(true);
      getApiCall(API_ACL_SETTING_LIST)
          .then(onAClSettingListSuccess)
          .catch(onError)
          .finally(() => setLoading(false));

  }, []);

   
   function onAClSettingListSuccess(resp){
      const updatedList = resp.map( (val, idx) => {
         val['key'] = idx+1;
         // val['actions'] = RenderActionComponent(val._id);
         val['createdAt'] = new Date(val['createdAt']).toGMTString();
         return val;
      } );
      setAClSettingList(updatedList);
   }

   async function handleDeleteAclSetting(id){
      checkAuth(setToastProps, setShowToast)
      setIsMultiDelete(false);
      setEnableDeleteModal(true);
      setDeleteACLId(id);
   }

   const handleSelectAll = () => {
      if (selectedAcl.length === aclSettingList.length) {
          setSelectedAcl([]);
      } else {
          const allAclIds = aclSettingList.map(acl => acl._id);
          setSelectedAcl(allAclIds);
      }
    };

    useEffect( () => {
      if (confirmDelete) {
          if (isMultiDelete) {
              postApiCall(API_ACL_SETTING_DELETE, { aclIds: selectedAcl })
                  .then(onDeleteSuccess)
                  .catch(onDeleteFail);
          } else {
              postApiCall(API_ACL_SETTING_DELETE, { aclIds: [deleteACLId] })
                  .then(onDeleteSuccess)
                  .catch(onDeleteFail);
          }
      }
      }, [confirmDelete]);

   function onDeleteSuccess(resp) {
    setToastProps({ type: 'success', message: 'ACL Setting deleted successfully' });
    let updatedList;
    if (isMultiDelete) {
        updatedList = aclSettingList.filter(val => !selectedAcl.includes(val._id)).map((val, key) => {
            val['key'] = key + 1;
            return val;
        });
        setSelectedAcl([]);
    } else {
        updatedList = aclSettingList.filter(val => val._id != deleteACLId).map((val, key) => {
         val['key'] = key + 1;
         return val;
      });
    }
    setAClSettingList(updatedList);
    setEnableDeleteModal(false);
    setDeleteACLId(undefined);
    setConfirmDelete(false);
 }

 function onDeleteFail(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
       errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
       setToastProps({ type: 'danger', message: `Unable to delete acl setting: ${err}` });
    }
 }


   if (loading) {
      return <Loader />
   }


   const RenderActionComponent = ({id}) => {
    const aclSettingEditRoute = ROUTES.ACL_SETTING.EDIT.replace(':id', id);
    return (
       <div className="flex align-items-center list-user-action">
          <EditIcon linkTo={`/${aclSettingEditRoute}`} />
          {' '}
          {!(selectedAcl.length > 0) && (
          <DeleteIcon handleCallback={() => handleDeleteAclSetting(id)} />
          )}
          {' '}
       </div>
    );

 }
   const dropdownItemTemplate = (option) => {
      return <span>{option}</span>;
   }


   
   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        console.log(err)
      }
   }

   const handleCreateButtonClick = () => {
      navigate(`/${ROUTES.ACL_SETTING.CREATE}`);
   } 


   const rowClassName = (data) => {
      return {
          'highlighted-row': selectedAcl.includes(data._id)
        };
    }

    const actionBodyTemplate = (rowData) => {
      return <RenderActionComponent id={rowData._id} />;
    };

  return(
     <>
       <div>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-block justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">ACL Setting List</h4>
                        {/* <RenderUserAccessRolesList activeUserAccessRole={activeUserAccessRole} userAccessRoles={userAccessRoles} setActiveUserAccessRole={setActiveUserAccessRole} /> */}
                     </div>
                     <button className="btn btn-primary ml-auto" onClick={handleCreateButtonClick}>Create ACL Setting</button>
                  </Card.Header>
                  <Card.Body className="px-0">
                     
                     <div className="table-responsive border-bottom my-3">
                        <Button 
                           label="Delete Selected" 
                           onClick={handleMultiDelete} 
                           className="p-button-danger p-button-sm" 
                           style={{ marginBottom: '10px', marginLeft: '10px' }} 
                           disabled={selectedAcl.length === 0} 
                        />
                        <Button 
                           label="Export Data" 
                           onClick={handleExport} 
                           className="p-button-active p-button-sm" 
                           style={{ marginBottom: '10px', marginLeft: '10px' }} 
                           disabled={buttonDisable} 
                        >
                           &nbsp;
                           {buttonDisable && (
                              <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                           )}
                        </Button>
                        <DataTable value={aclSettingList} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8} rowClassName={rowClassName}>
                         <Column 
                           header={<Checkbox 
                           checked={selectedAcl.length === aclSettingList.length && aclSettingList.length > 0} 
                           onChange={handleSelectAll} 
                           />} 
                           headerStyle={{ width: '3rem' }} 
                           body={(rowData) => (
                           <Checkbox 
                              checked={selectedAcl.includes(rowData._id)} 
                              onChange={() => handleCheckboxToggle(rowData._id)} 
                           />
                           )} 
                          />
                           <Column field="acl_name" filterField="ACL Name" header="ACL Name"></Column>
                           <Column field="createdAt" header="Created Date"></Column>
                           <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                     </div>

                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </div>

      <RenderDeleteModal 
         show={enableDeleteModal} 
         setShow={setEnableDeleteModal} 
         setConfirmDelete={setConfirmDelete} 
         isMultiDelete={isMultiDelete} 
         clearSelectedData={() => setSelectedAcl([])}
      />

      <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

     </>
  )

}

export default ACLSettingList;