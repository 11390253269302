import React, { useEffect, useState } from "react";
import { Row, Col, Image, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../../components/Card";
import ToastNotification from "../../../components/Toast";
import { API_USERS_SIGNIN } from "../../../service/url";
import { postApiCall } from "../../../service/httpCall";
import { storeUser } from "../../../utilities/setting";
import { ROUTES } from "../../../utilities/constant";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/setting/actions";

// img
// import facebook from '../../../assets/images/brands/fb.svg'
// import google from '../../../assets/images/brands/gm.svg'
// import instagram from '../../../assets/images/brands/im.svg'
// import linkedin from '../../../assets/images/brands/li.svg'
import auth1 from "../../../assets/images/auth/01.png";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  const validate = () => {
    let valid = true;

    if (username.trim() == "") {
      valid = false;
      setToastProps({ message: "Username required", type: "danger" });
    } else if (password.trim() == "") {
      valid = false;
      setToastProps({ message: "Password required", type: "danger" });
    }

    return valid;
  };

  const submit = (e) => {
    setButtonDisable(true);

    if (!validate()) {
      setButtonDisable(false);
      return;
    }
    if (validate()) {
      const params = { username, password };

      postApiCall(API_USERS_SIGNIN, params)
        .then(loginSuccess)
        .catch((err) => {
          setButtonDisable(false);
          const errorMsg = String(err);
          setToastProps({ message: errorMsg, type: "danger" });
        });
    }
  };

  function loginSuccess(resp) {
    //Store in (async local) storage and then redirect to dashboard
    storeUser(resp, function () {
      setToastProps({ message: "Login successful", type: "success" });
      dispatch(setUser(resp));
      setTimeout(function () {
        // navigate(`/${ROUTES.USERS.LIST}`);
        navigate(`/${ROUTES.DASHBOARD}`);
      }, 3500);
    });
  }

  return (
    <>
      <section className="login-content">
      <Row className="m-0 align-items-center bg-white vh-100 justify-content-center">
      <Col md="6" className="d-flex justify-content-center">
        <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
          <Card.Body>
            <Link
              to="/dashboard"
              className="navbar-brand d-flex align-items-center mb-3"
            >
              <svg
                width="30"
                className="text-primary"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="-0.757324"
                  y="19.2427"
                  width="28"
                  height="4"
                  rx="2"
                  transform="rotate(-45 -0.757324 19.2427)"
                  fill="currentColor"
                />
                <rect
                  x="7.72803"
                  y="27.728"
                  width="28"
                  height="4"
                  rx="2"
                  transform="rotate(-45 7.72803 27.728)"
                  fill="currentColor"
                />
                <rect
                  x="10.5366"
                  y="16.3945"
                  width="16"
                  height="4"
                  rx="2"
                  transform="rotate(45 10.5366 16.3945)"
                  fill="currentColor"
                />
                <rect
                  x="10.5562"
                  y="-0.556152"
                  width="28"
                  height="4"
                  rx="2"
                  transform="rotate(45 10.5562 -0.556152)"
                  fill="currentColor"
                />
              </svg>
              <h4 className="logo-title ms-3">Cloud Telephony</h4>
            </Link>
            <h2 className="mb-2 text-center">Sign In</h2>
            <p className="text-center">Login to stay connected.</p>
            <Form>
              <Row>
                <Col lg="12">
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="username" className="">
                      Username
                    </Form.Label>
                    <Form.Control
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      type="text"
                      className=""
                      id="username"
                      aria-describedby="username"
                      placeholder=" "
                    />
                  </Form.Group>
                </Col>
                <Col lg="12" className="">
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="password" className="">
                      Password
                    </Form.Label>
                    <Form.Control
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className=""
                      id="password"
                      aria-describedby="password"
                      placeholder=" "
                    />
                  </Form.Group>
                </Col>
                <Col lg="12" className="d-flex justify-content-between">
                  <Form.Check className="form-check mb-3">
                    <Form.Check.Input type="checkbox" id="customCheck1" />
                    <Form.Check.Label htmlFor="customCheck1">
                      Remember Me
                    </Form.Check.Label>
                  </Form.Check>
                  <Link to="/auth/recoverpw">Forgot Password?</Link>
                </Col>
              </Row>
              <div className="d-flex justify-content-center">
                <Button
                  onClick={submit}
                  type="button"
                  variant="btn btn-primary"
                  disabled={buttonDisable}
                >
                  Sign In &nbsp;
                  {buttonDisable ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <></>
                  )}
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>

        <ToastNotification
          show_toast={showToast}
          set_show_toast={setShowToast}
          {...toastProps}
        />
      </section>
    </>
  );
};

export default SignIn;
